@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600&display=swap");

$pq_margin: 5%;
$pq_key_color: #0d2841;
$pq_support_color: #ffffff;
$pq_light_blue: #0572cc;
$pq_embedded_color: #fbc21d;
$pq_design_color: #1afff0;
$pq_dark_grey: #5f5f5f;
$pq_darker_key: #091927;
$pq_light_grey: #ebebeb;
$pq_font_large: 45px;
$pq_font_mid_large: 20px;
$pq_font_regular: 19px;
$pq_font_small: 15px;
$pq_font_dropdowns: 17px;
$pq_font_product_title: 30px;
$pq_bold: 700;
$pq_semibold: 600;
$pq_regular: 500;
$pq_light: 300;
$button_border_radis: 3px;

$title_font_family: "Barlow", sans-serif;
$text_font_family: "Barlow", sans-serif;

$banner_padding: 10px 25px;

/* header */
$pq_header_height: calc(100vh / 10);
$pq_dropdown_height: calc(100vh / 3);
$pq_mobile_header_height: calc(100vh / 10);

/* banner */
$pq_banner_mobile: 90%;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $title_font_family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  font-size: $pq_font_regular;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.page {
  height: 100%;
}

input[type="text"],
input[type="phone"],
input[type="email"],
input[type="password"],
textarea,
select {
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $pq_key_color;
}

/* Handle */
::-webkit-scrollbar-thumb { 
  background: $pq_dark_grey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $pq_light_grey;
}

/* ######################## BUTTON COMPONENT ######################## */
.btn {
  cursor: pointer;
  font-size: $pq_font_regular;
  padding: 10px 20px;
  width: fit-content;
  margin: auto;
  font-weight: $pq_regular;
  border-radius: $button_border_radis !important; //all button radius to 3px
}

.btn.white {
  color: $pq_key_color;
  background: $pq_support_color;
}

.btn.dark {
  background: $pq_key_color;
  color: $pq_support_color;
}

.btn:hover {
  opacity: 0.8;
}

.line {
  border-left: 2px solid $pq_key_color;
  height: 40px;
  position: absolute;
  left: calc(50vw - 1px);
  top: 17px;
}

.download-datasheet-btn{
  background: $pq_key_color !important;
  color: $pq_support_color;
  border: none !important;
  padding: 20px;
}

.circle {
  height: 13px;
  width: 13px;
  background-color: $pq_key_color;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: calc(50vw - 7px);
  top: 2px;
}



.outer {
  background-color: transparent;
  border: 1px solid $pq_key_color;
  height: 15px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 15px;
  position: absolute;
  left: calc(50vw - 9px);
}

.outer.bottom {
  top: 57px;
}

.circle.bottom {
  top: 59px;
}

.connector-container {
  position: relative;
  height: 75px;
}

.product-feature-diagram {
  text-align: center;
  font-size: $pq_font_regular;
}



.product-feature-diagram p {
  margin: 10px $pq_margin;
  color: $pq_key_color;
  font-family: $text_font_family;
}

.product-features {
  padding: $pq_margin 0;
}

.product-screens {
  margin-top: $pq_margin;
  padding: 0 $pq_margin;
  background: $pq_key_color;
  position: relative;
}

.product-screens img {
  width: 100%;
}

.product-screens.web img {
  cursor: pointer;
}

.product-screens h4 {
  text-align: center;
  color: $pq_support_color;
  padding: 20px 0;
  font-size: $pq_font_mid_large;
  font-weight: $pq_regular;
}

.product-screen {
  padding-bottom: $pq_margin;
}

.product-screen p {
  text-align: center;
  color: $pq_support_color;
  margin-top: 0;
  font-family: $text_font_family;
  font-size: $pq_font_small;
}

/* ######################## BLUEBANNER SECTION ######################## */

.blue-banner {
  height: 30vh;
  background: $pq_key_color;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue-banner h4 {
  color: $pq_support_color;
  font-size: $pq_font_large;
  font-weight: $pq_regular;
  width: 100%;
  text-align: center;
}

.blue-banner.right {
  justify-content: flex-end;
}

.blue-banner.right h4 {
  text-align: right;
  margin-right: $pq_margin;
}

/* ######################## HEADER SECTION ######################## */
.header {
  height: $pq_header_height;
  background-color: $pq_key_color;
  padding-left: 5%;
  box-shadow: 0 2px 5px $pq_darker_key;
  display: flex;
  align-items: center;
}

.header-mobile {
  display: none;
  
}

.header-left {
  // width: 15%;
  width: 17%;
}

.header-left img {
  width: 80%;
}

.header-right {
  // width: 85%;
  // height: 100%;
  // display: flex;
  // align-items: center;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-solution {
  width: 18%;
  text-align: center;
}

.header-solution-de {
  width: 18%;
  text-align: center;
}

.header-solution a{
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-size:17px;
}

.header-solution-de a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-size:17px;
}
.header-solution-container-em{
  display: flex;
  justify-content: center;
  align-items: center;
  height:calc((100vh / 10) - 15px);
  border-radius: 5px;
  background-color: #d9d9d925;
  margin-right: 4px;

}
.header-solution-container-de{
  height:calc((100vh / 10) - 15px);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9d9d925;
margin-right: 4px;
  margin-left: 2px;
}
// .header-solution a {
//   text-decoration: none;
//   color: $pq_embedded_color;
//   -webkit-transition: all 0.1s ease-in-out;
//   -moz-transition: all 0.1s ease-in-out;
//   -o-transition: all 0.1s ease-in-out;
//   transition: all 0.1s ease-in-out;
// }

// .header-solution-de a {
//   text-decoration: none;
//   color: $pq_design_color;
//   -webkit-transition: all 0.1s ease-in-out;
//   -moz-transition: all 0.1s ease-in-out;
//   -o-transition: all 0.1s ease-in-out;
//   transition: all 0.1s ease-in-out;
// }

.header-solution a:hover {
  font-weight: $pq_bold;
}

.header-solution-de a:hover {
  font-weight: $pq_bold;
}

.header-sections {
  // width: 80%;
  // height: 100%;
  // display: flex;
  // justify-content: flex-end;

  width: 100%;
  height: 100%;
 margin-right: 2px;
  display: flex;
  justify-content:flex-end;
}

.item1 {
  margin-right: 10px; 
  width: 15% !important;
}

.header-section {
  width: 11%;
  font-size: $pq_font_dropdowns;
  font-weight: $pq_font_mid_large;
  font-family: $title_font_family;
  text-align: center;
  color: $pq_support_color;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-section-contact:hover > def,
.header-section-contact:hover > a,
.header-section-contact:active > def,
.header-section-contact:active > a {
  font-weight: $pq_bold;
}

.header-selected-bar {
  bottom: 0;
  width: 100%;
  position: absolute;
  opacity: 0;
}

.header-selected-bar-hidden {
  display: none;
  bottom: 0;
  width: 100%;
  position: absolute;
  opacity: 0;
}

.header-section.selected > .header-selected-bar {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.header-dropdown {
  width: 50%;
  margin-left: 30%;
  background: $pq_key_color; //dropdown color change
  position: absolute;
  z-index: -1;
  top: $pq_header_height;
  opacity: 1;
  display: flex;
  padding-bottom: 1vh;
  box-shadow: 0px 0px 5px $pq_darker_key;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 3px; //dropdown menu b-radius to 3px
}

.header-dropdown-container.half > .header-dropdown {
  width: 35%;
  margin-top: -15px;
  margin-left: 40%;
  background-color: $pq_key_color;
  box-shadow: 0px 0px 5px $pq_darker_key;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-dropdown-container.hidden {
  opacity: 0;
  height: 0;
}

.header-dropdown-container.hidden > .header-dropdown {
  opacity: 0;
  height: 0;
}

.header-dropdown .col {
  width: 25%;
}

.header-dropdown-container.half .header-dropdown .col {
  width: 50%;
  height: 215px;
}

.col3-customize{
  margin-left: -110px;
}

/* HOME-BANNER */

.home-banner {
  height: auto;
}

.banner-web {
  display: block;
  position: relative;
  height: 100%;
}

.banner-mobile {
  display: none;
}

.banner-content {
  display: flex;
  height: 65vh;
}

.banner-img.selected {
  width: 100%;
  z-index: -1;
  transition: 1s;
}

.banner-img {
  width: 0%;
  transition: 1s;
}

.banner-title {
  position: absolute;
  top: 10%;
  color: $pq_support_color;
  left: $pq_margin;
}


.banner-title h1 {
  font-size: $pq_font_large;
  font-weight: $pq_regular;
}

.banner-title-black {
  color: $pq_darker_key !important;
}


.banner-subtitle {
  position: absolute;
  top: 25%;
  color: $pq_support_color;
  left: $pq_margin;
  font-family: $title_font_family;
  font-weight: $pq_light;
}

.banner-subtitle-black{
  color: $pq_darker_key !important;
}

.banner-button {
  position: absolute;
  top: 42%;
  left: $pq_margin;
  padding: $banner_padding;
  background: $pq_key_color;
  cursor: pointer;
  border-radius: $button_border_radis;
}

.banner-button.cb {
  left: 45%;
  top: 85%;
  border-radius: $button_border_radis;

}

.banner-button a {
  color: $pq_support_color;
  text-decoration: none;
  
}

.banner-button:hover {
  opacity: 0.7;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.banner-indicator {
  display: flex;
  position: absolute;
  bottom: 10px;
  width: 100%;
  justify-content: center;
  gap:10px
}

.indicator-item img {
  height: 12px;
  cursor: pointer;
}

.indicator-item.selected img {
  height: 12px;
  

}

/* CATEGORIES */

.home-categories {
  margin: 0 $pq_margin;
  overflow: auto;
}

.home-title {
  text-align: center;
}

.home-title h1 {
  color: $pq_key_color;
  font-weight: $pq_light;
}

.home-title p {
  color: $pq_dark_grey;
  font-family: $text_font_family;
  font-weight: $pq_light;
  font-size: $pq_font_regular;
}

.home-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 3);
  float: left;
  cursor: pointer;
  padding-top: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.home-category:hover {
  background: $pq_light_grey;
}

.home-category.mid {
  flex-direction: column-reverse;
}

.home-category-container {
  margin: $pq_margin;
  overflow: auto;
}

.home-category-text {
  text-align: center;
  margin: 0 10%;
  color: $pq_key_color;
}

.home-category-text p {
  font-family: $text_font_family;
  font-size: $pq_font_regular;
  color: $pq_dark_grey;
}

.home-category-text h2 {
  font-family: $title_font_family;
  font-size: $pq_font_mid_large;
  color: $pq_key_color;
  font-weight: $pq_regular;
}

.home-category-icon {
  text-align: center;
  height: 20vh;
  display: flex;
  align-items: center;
}

.home-category-icon img {
  height: 75%;
}

.home-category-text {
  height: 20vh;
}

/* INFOGRAPH */

.home-info-hud {
  display: none;
}

.home-info-hud-web {
  display: flex;
  height: 100vh;
  background: $pq_key_color;
  justify-content: center;
  align-items: center;
}

.home-info-see {
  opacity: 1;
  cursor: pointer;
}

.home-info-see:hover {
  opacity: 0.4;
}

.home-info-unsee {
  opacity: 0;
}

.home-info-inblue path {
  fill: $pq_key_color;
}

.home-info-inwhite path {
  fill: $pq_support_color;
}

.info-container-wrapper .title {
  fill: $pq_support_color;
  font-size: 3em;
  font-family: $title_font_family;
}

.info-container-wrapper .description {
  fill: $pq_support_color;
  font-size: 2em;
  font-family: $text_font_family;
}

.info-container-wrapper:hover {
  cursor: pointer;
}

.info-container-wrapper:hover > .box {
  fill-opacity: 1;
}

.info-container-wrapper:hover > .textbox {
  fill: $pq_support_color;
}

.info-container-wrapper:hover .title {
  fill: $pq_key_color;
}

.info-container-wrapper:hover .description {
  fill: $pq_key_color;
}

/* FEATURES */

.home-features-content-mobile {
  display: none;
}

.home-features-content-web {
  display: block;
  height: auto;
  margin: 0 $pq_margin;
  padding-bottom: $pq_margin;
}

.home-features-sidebar {
  display: none;
}

.home-features-content-web-products {
  display: flex;
  padding-top: 10vh;
  margin-bottom: 10vh;
  border-bottom: 1px solid $pq_dark_grey;
}

.home-features-content-web-product {
  width: 15%;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background: $pq_support_color;
  margin: 0 5px;
}

.home-features-content-web-product:hover {
  background: $pq_light_grey;
}

.home-features-content-web-product.selected {
  background: $pq_key_color;
  color: $pq_support_color;
}

.home-features-content-web-features {
  display: flex;
  height: 60vh;
}

.home-features-content-web-list {
  width: 45%;
  overflow: auto;
  padding-right: 5%;
}

.home-features-content-web-video {
  width: 50%;
}

.home-feature-item {
  padding: 35px 20px;
}

.home-feature-item h4 {
  font-family: $text_font_family;
  color: $pq_key_color;
  margin: 0;
  margin-bottom: 10px;
}

.home-feature-item p {
  font-family: $text_font_family;
  color: $pq_dark_grey;
  margin: 0;
  padding-bottom: 20px;
}

.home-feature-item hr {
  color: $pq_light_grey;
  width: 75%;
  opacity: 0.3;
  margin-bottom: -35px;
}

.home-feature-item:hover {
  cursor: pointer;
  // opacity: 0.1;
  background: $pq_light_grey;
}

// .home-feature-item:hover p {
//   color: $pq_support_color;
// }

// .home-feature-item:hover h4 {
//   color: $pq_support_color;
// }

// .home-feature-item:hover hr {
//   opacity: 0;
// }

.home-feature-item.selected {
  background: $pq_key_color;
}

.home-feature-item.selected p {
  color: $pq_support_color;
}

.home-feature-item.selected h4 {
  color: $pq_support_color;
}

.home-feature-item.selected hr {
  opacity: 0;
}

/* FOOTER */
/* FOOTER */
.footer {
  height: 100vh;
  background: $pq_darker_key;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-container {
  width: 100%;
  margin: 0 5%;
  text-align: center;
}

.footer-container img {
  width: 30%;
}

.footer-contact {
  display: flex;
  color: $pq_support_color;
  font-family: $text_font_family;
  font-size: $pq_font_regular;
  justify-content: center;
}

.footer-email {
  width: 48%;
  font-size: $pq_font_regular;
  text-align: right;
}

.footer-filler {
  width: 2%;
  text-align: center;
}

.footer-number {
  width: 48%;
  text-align: left;
}

.footer-address {
  margin-top: 20px;
  color: $pq_support_color;
  font-size: $pq_font_small;
  font-family: $title_font_family;
}

.footer-address p {
  margin: 5px 0;
}

.footer-privacy-links p a{
  color: rgba(206, 205, 205, 0.819) !important;
}

.footer-social {
  display: flex;
  justify-content: center;
  margin: 2%;
}

.footer-social img {
  width: 65%;
}

.footer-social a {
  width: 60px;
}

.footer-links {
  color: $pq_support_color;
}

.footer-links a {
  text-decoration: underline;
  color: $pq_support_color;
  font-size: $pq_font_regular;
  padding: 10px;
}

.footer-email a {
  text-decoration: none;
  color: $pq_support_color;
  font-size: $pq_font_regular;
}

.footer-number a {
  text-decoration: none;
  color: $pq_support_color;
  font-size: $pq_font_regular;
}

.footer-email a:hover {
  color: $pq_light_grey;
}

.footer-number a:hover {
  color: $pq_light_grey;
}


// ####################### New footer styles ###########################


.footerNew{
  background-color: $pq_darker_key;
  padding: 40px 100px 30px 100px;
 color: $pq_support_color;
}

.footerNew-container{
display: flex;
justify-content: flex-start;
align-items: flex-start;
width: 100%;


}

.footerNew-section1{
width: 35%;
margin-right: 60px;
margin-top: 20px;


}


.footerNew-section2{
  width: 30%;
  display: flex;
  justify-content: flex-end;
  margin-left: -80px;
  padding-right: 12px;


}
.footerNew-section3{
  width: 30%;
  display: flex;
  justify-content: flex-end;
  margin-right: -73px;

}

.footerNew-section4{
  width: 30%;
  display: flex;
  justify-content: flex-end;
  margin-left: -25px;
}

.footerNew-pq-logo{
  width: 180px;
  margin-left: 15px;
}

.footerNew-section1 img {
  width: 180px;
}

.rights-text{
  margin-left: 15px;
  margin-top: 40px;
}

.wispa-text{
 margin-top: 7px;
}

.footerNew-social{
margin-top: 22px;
margin-left: -14px;
}
.footerNew-social img{
width: 35px;
margin-left: 15px;
}

.footerNew-container p{
  margin-bottom: 30px;
}

.footerNew-topics{
  font-weight: 700;
  color: white !important;
  margin-left: 15px;
}

.footerNew-topics::before{
  content: '';
  position: absolute;
  width:4px;
  height: 16.007px;
  background-color: #196BBF;
   margin-top: 5px;
   margin-left: -15px;
   border-radius: 3px;
}

.footerNew-topics-content-top{
display: flex;
margin-top: 30px;
width: 100%;
color: rgba(248, 248, 248, 0.856);
}

.footerNew-topics-content{
  display: flex;
  width: 100%;
  color: rgba(248, 248, 248, 0.856);
  }

 
  
  .footerNew-section2 p a{
   
    text-decoration: none;
    color: $pq_light_grey;

  }

  .footerNew-section2 p a:hover{
    color: white;
    font-weight: 500;
  }

  .footerNew-section2 ul:hover{
    margin-left: -25px;
    text-decoration: underline;

  }

  .footer-email-a{
    margin-right: px;
    width: 100%;
    text-decoration: underline;

  }


  .footerNew-section3 p{
    color: rgba(248, 248, 248, 0.856);
    margin-bottom: 2px;
  }

  .footerNew-section2-list {
    margin-top: 31px;
    color: $pq_light_grey;
    line-height: 0.9;
  }


  
  .footer-links{
    margin-left: 15px;
  }

  .footerNew-section4 p{
margin-bottom: 2px;
  }

  .footerNew-hr{
    margin-top: 10px;
    border:1px solid rgba(224, 220, 220, 0.742) !important;
  }

  .footerNew-number{
    width: 100%;
    text-align: left;
    color: rgba(248, 248, 248, 0.856);
  }



  .footerNew-number a {
    text-decoration: none;
    color: $pq_support_color;
    font-size: $pq_font_regular;
    
  }

  .footerNew-number a:hover {
  color: $pq_light_grey;
}
  

  .footerNew-mb{
    // margin-bottom: 2px !important;
  }
  



/* PRODUCT */

.product-header {
  text-align: center;
  margin: 0 $pq_margin;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -110px;
}

.product-web-button {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}

.product-features {
  padding: 0;
}

.pq-scroll-button {
  display: none;
}

.product-wrapper {
  width:65%;
}

.product-wrapper p {
  font-family: $title_font_family;
  font-size: $pq_font_regular;
  font-weight: $pq_regular;
  color: $pq_dark_grey;
}

.product-wrapper h1 {
  font-family: $title_font_family;
  font-size: $pq_font_large;
  font-weight: $pq_regular;
  color: $pq_key_color;
  margin-top: -50px;
}

.product-mobile-features {
  display: none;
}

.product-web-features {
  display: flex;
  justify-content: center;
}

.product-feature-mid {
  width: 20%;
}

.product-feature-mid img {
  width: 100%;
}

.product-feature-left {
  width: 40%;
}

.product-feature-right {
  width: 40%;
}

.product-item {
  height: 12.8vh;
  display: flex;
  align-items: center;
}

.product-feature-left .product-item {
  justify-content: flex-end;
}

.product-feature-left .product-item p {
  text-align: right;
  cursor: pointer;
  padding-left: 2 * $pq_margin;
  color: $pq_dark_grey;
  font-size: $pq_font_regular;
}

.product-feature-left .product-item p:hover {
  font-weight: 900;
  border-radius: 10px;
}



.product-feature-right .product-item {
  justify-content: flex-start;
}

.product-feature-right .product-item p {
  text-align: left;
  cursor: pointer;
  padding-right: 2 * $pq_margin;
  color: $pq_dark_grey;
  font-size: $pq_font_regular;
}

.product-feature-right .product-item p:hover {

  font-weight: 900;
}

.product-item p {
  padding-left: 5%;
  padding-right: 5%;
}

.product-item.top {
  height: 13vh;
}

.product-screens.mobile {
  display: none;
}

.product-screens.web {
  display: block;
}

.css-1qzevvg {
  display: none !important;
}

.css-1fzpoyk {
  height: auto !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.css-1fzpoyk:nth-child(3) {
  transform: translateY(-50%) translateX(250%) scale(2) !important;
  right: 75% !important;
  left: 0 !important;
}

.cnav-container {
  display: flex;
  width: 10%;
}

.cnav-holder {
  display: flex;
  justify-content: center;
  z-index: 99;
  position: absolute;
  bottom: 0;
  width: 65%;
}

.cnav-item img {
  padding: 20px;
}

.product-screen-subtitle {
  display: none;
}

.product-screen-title {
  font-size: $pq_font_large;
  padding-top: $pq_margin;
  text-align: center;
  color: $pq_support_color;
}


// ############### Product New screen styling ###################


.product-header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
width: 100%;
  margin-top: $pq_header_height;
  background-repeat: no-repeat;
  height: 65vh;
  background-size:cover;
  // background-position: 0px -15px;
}

.product-header-wrapper p {
  position: absolute;
  top: -30%;
  left: 0;
  transform: translate(-40%, -50%) rotate(-90deg); /* Adjusted for vertical centering */
  color: rgba(255, 255, 255, 0.844);
  font-family: 'Montserrat';
  font-size: 70px;
  font-weight: 600;
  opacity: 0.07;
  white-space: nowrap; /* Prevent text from wrapping */
}

.product-watermark-ceyseries{
  font-size:55px !important;
  top: -20% !important;
}

.product-watermark-defualt{
  font-size: 70px !important;
}

.product-watermark-trafficshaper{
  font-size: 45px !important;
  top: -10% !important;
}

.product-watermark-networkanalyzer{
  font-size: 38px !important;
  top:0 !important;
}

.product-watermark-submanager{
  font-size: 35px !important;
  top:0 !important;
}

.product-watermark-widi{
  font-size: 90px !important;
  top: -50% !important;
  left:2% !important;

}


// .product-header-wrapper::before {
//   content: "";
//   margin-top: $pq_header_height;
//   position: absolute;
//   top: 0;
//   height:65vh;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   // background-color: rgba(13, 40, 65, 0.771);
//   // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2));
//   z-index: 1;
// }
.product-header-wrapper > * {
  position: relative;
  z-index: 2;
}

.product-wrapper{
  width: 50%;
  margin-left: 20px;
  padding-left: 140px;
  padding-bottom: 40px;
}

.product-mockup{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.product-buttonNew{
text-align: left;

padding: 12px 26px;
background-color: $pq_darker_key;
color: white;
border: none;
cursor: pointer;
font-size: 14px;
border-radius: 5px;
}

.product-title{
  font-weight: 700 !important;
  text-align: center;
  color: white!important;
}

.product-buttonNewWrapper{
  margin-top: 30px;
  text-align: center;
}

.product-description{
  text-align: center;
  color: rgba(201, 200, 200, 0.89) !important;

  // color: $pq_light_grey !important;
}



.productNew-header{
  position: relative;
  margin-top: 16%;
}

.product-header-wrapper-p{
  width: 60%;
  text-align: center;
  color: $pq_dark_grey;
  font-weight: 500;
}



/* ############## Tooltip Styling ################*/


.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;

}

.tooltip-text {
  visibility: hidden;
  width: 60%;
  background-color: #333333ee;
  color: #fffffff6;
  font-size: 16px;
  text-align: justify;
  border-radius: 10px;
  padding: 10px 20px;
  position: absolute;
  z-index: 10;
  bottom: 100%; 
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px; 
  border-width: 8px;
  border-style: solid;
  border-color: #333333ee transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* CATEGORY */

.category-banner-mobile {
  display: none;
}



.category-banner-web {
  display: block;
}

.category-banner img {
  width: 100%;
}

.category-icons {
  text-align: center;
}

.category-banner {
  position: relative;
}

.category-container {
  position: absolute;
  bottom: 30%;
  text-align: right;
  width: 70%;
  color: $pq_support_color;
  right: $pq_margin;
}

.category-container h1 {
  font-size: $pq_font_large;
  font-family: $title_font_family;
  font-weight: $pq_regular;
}

.category-container p {
  font-size: $pq_font_regular;
  font-family: $text_font_family;
}

.category-intro {
  height: 75vh;
  background: $pq_key_color;
  position: relative;
  padding-right: $pq_margin;
}

.category-intro img {
  position: absolute;
  height: 50%;
  top: 25%;
}

.category-intro-content {
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: 80px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.category-intro-content-wrapper {
  width: 60%;
  color: $pq_support_color;
  text-align: justify;
  font-size: $pq_font_regular;
  line-height: 2em;
}

.category-intro-content-wrapper p {
  text-align: justify;
}

.category-intro-content-wrapper a {
  padding: 5px 20px;
  background: $pq_support_color;
  color: $pq_key_color;
  text-decoration: none;
  border-radius: $button_border_radis;
}

.category-intro-content-wrapper a:hover {
  opacity: 0.5;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.category-product {
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.category-product-details {
  padding-top: 20px;
  display: flex;
}

.category-product-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-product-details img {
  width: 35%;
}

.category-product-text {
  width: 70%;
  margin-left: $pq_margin;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-product hr {
  width: 75%;
  margin-top: 25px;
  margin-bottom: 0;
  opacity: 0.2;
}
.category-product:hover {
  background-color: $pq_light_grey;
}
.category-products {
  margin: 0 $pq_margin;
  padding: $pq_margin 0;
}

.category-product-text h5 {
  color: $pq_key_color;
  margin: 5px 0;
  font-size: $pq_font_product_title;
  padding-bottom: $pq_margin;
  font-weight: $pq_regular;
}

.category-product-text p {
  color: $pq_dark_grey;
  margin: 5px 0;
  font-family: $text_font_family;
  font-size: $pq_font_regular;
  padding-bottom: ($pq_margin * 2);
}

.category-product-text a {
  padding: 8px 10px;
  font-size: $pq_font_regular;
  background: $pq_key_color;
  color: $pq_support_color;
  text-decoration: none;
  font-family: $text_font_family;
  margin-top: 5px;
  border-radius: $button_border_radis;
}
.category-product-text a:hover {
  color: $pq_light_grey;
  opacity: 0.8;
}
.col-header {
  padding: (2 * $pq_margin);
  font-weight: $pq_bold;
}

.col a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  color: $pq_support_color;
}

.col-header-item-events{
  position: absolute;
  top: 0;
  margin-left: 127px;
  margin-top: 41px;
  padding: $pq_margin (2 * $pq_margin);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.col-header-item-events:hover{
  margin-left: 132px;
}

.col-header-item {
  padding: $pq_margin (2 * $pq_margin);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.col-header-item:hover {
  margin-left: 5px;
}

.header-dropdown.half .col3 {
  width: 50%;
}

.header-section a {
  text-decoration: none;
  color: $pq_support_color;
}

.vertical-hr {
  width: 0.01px;
  height: 50%;
  display: inline-block;
  color: $pq_support_color;
  border-style: solid;
  opacity: 0;
}

.header-dropdown-container .header-dropdown .vertical-hr {
  height: auto;
}

.header-dropdown-container.half .vertical-hr {
  display: none;
}

.header-container {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
}

.page {
  margin-top: $pq_header_height;
}

.col4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col4-image{
  margin-left: 100px;
}


/* CONTACT */

.contact-section {
  margin: 0 $pq_margin;
}

.contact-top {
  width: 100%;
  display: flex;
  margin: $pq_margin 0;
}

.contact-half {
  width: 50%;
}

.contact-half h2 {
  font-weight: $pq_regular;
  color: $pq_key_color;
  margin-top: 0;
  margin-bottom: 30px;
}

.pq-map {
  width: 100%;
  height: 100%;
}

.contact-element {
  display: flex;
  margin-bottom: 30px;

}

.contact-element img {
  width: 2em;
  margin-top: 3px;
}

.contact-column h4 {
  margin: 0 0 10px 0;
  color: $pq_key_color;
}

.contact-column p {
  margin: 10px 0;
  color: $pq_dark_grey;
  font-family: $text_font_family;
  font-size: $pq_font_regular;
  line-height: 1.6em;
}

.contact-column p a {
  text-decoration: none;
  color: $pq_key_color;
}

.contact-column p a:hover {
  font-weight: $pq_bold;
}

.contact-column {
  margin-left: 20px;
}

.contact-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: $pq_margin;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact-form p {
  text-align: center;
  color: $pq_key_color;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  width: 60%;

}

.contact-form form input {
  margin: 10px 0;
  padding: 10px;
  font-family: $title_font_family;
  border: 1px solid rgb(196, 196, 196);


}

.contact-form form textarea {
  margin: 10px 0;
  padding: 10px;
  font-family: $title_font_family;
  border: 1px solid rgb(196, 196, 196);
  height: 100px;
}

.contact-form h2 {
  font-weight: 700 !important;
  color: $pq_key_color;
  margin-top: 0;
  margin-bottom: 30px;
}

.contact-regions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.contact-regional-partners{
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 100px;
}

.contact-regions h2 {
  font-weight: $pq_regular;
  color: $pq_key_color;
  margin-top: 0;
  margin-bottom: 30px;
}

.contact-partners {
  margin-bottom: $pq_margin;


}

.contact-partners h4 {
  margin: 0 0 10px 0;
  color: $pq_key_color;
}

.contact-partners p {
  margin: 10px 0;
  color: $pq_dark_grey;
  font-family: $text_font_family;
  font-size: $pq_font_regular;
  line-height: 1.6em;
}

.contact-partners p a {
  text-decoration: none;
  color: $pq_key_color;
}

.contact-partners p a:hover {
  font-weight: $pq_bold;
}


// ###############  Global Locations ################

.global-locations{
  margin-top: 50px;
  margin-bottom: 50px;
}

.global-locations-upper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.global-locations-upper h1{
  color: $pq_darker_key;
  opacity: 75%;
  font-family: 'montserrat';
  font-size: 30px;
  margin-bottom: -15px;
}

.global-locations-map-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.global-locations-map-sl{
  position: relative;
  width: 80%;
  height:80% ;
  z-index: 2;
}

.global-locations-indicator{
  position: absolute;
  width: 130px;
  z-index: 1000;

}

.global-locations-indicator-hover{
transform: scale(1.3) !important;
}

.global-locations-indicator-srilanka{
  margin-left:21.6%;
  margin-top: 12.4%;
}

.global-locations-indicator-australia{
  margin-left: 41%;
  margin-top: 24%;
}

.global-locations-indicator-america{
  margin-right: 33%;
  margin-bottom: 2%;
}

.global-location-adresses{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 18px;

}


.global-location-section{
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 23%;
  margin-left: 2%;
  transition: transform 0.3s ease;
}

.global-location-section:hover{
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 9px -3px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 9px -3px rgba(0,0,0,0.75);
box-shadow: 0px 0px 9px -3px rgba(0,0,0,0.75);
transform: scale(1.01);
}

.global-location-region{
  font-size: 23px;
  font-weight: 700;
  text-align: left;
  color: $pq_darker_key;
}

.global-location-address{
  margin-bottom: -12px;
  color: rgb(90, 88, 88);
}
.global-location-email{
  margin-top: 40px;
  margin-bottom: -15px;
}

.global-location-email a{
  color: black;
  font-weight: 500;
}

.global-location-phoneNumber a{
  color: black;
  font-weight: 500;
  font-size: 18px;
}



/* ABOUT */

.dot-container {
  position: relative;
}

.dot-container .circle {
  left: 50%;
  background: $pq_support_color;
}

.dot-container .outer {
  left: calc(50% - 2px);
  border: 1px solid $pq_support_color;
}

.about-title {
  margin: 0 10%;
  text-align: center;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 $pq_margin;
  margin-bottom: 100px;
}

.about-title.top {
  margin-top: $pq_margin;
}

.about-title h2 {
  font-size: 35px;
  color: $pq_key_color;
  font-weight: $pq_regular;
}

.about-title p {
  font-size: $pq_font_regular;
  font-family: $title_font_family;
  font-weight: $pq_light;
  color: $pq_dark_grey;
}

.about-title.title {
  height: auto;
}

.about-button {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: $pq_margin;
  padding: 0 $pq_margin;
}

.about-button a {
  padding: 30px;
  margin: 10px;
  text-decoration: none;
  background: $pq_key_color;
  width: 12em;
  text-align: center;
}

.about-button a:hover {
  // font-weight: $pq_bold;
  background-color: #0d2841e8;
}

.about-internal {
  color: $pq_support_color;
  border-radius: 3px;
}

.about-external {
  color: $pq_embedded_color;
}

.circle.yellow {
  background: $pq_embedded_color;
}

.story-section {
  margin: 35px 15%;
  text-align: center;
  font-family: $text_font_family;
  color: $pq_dark_grey;
}

.story-section p {
  padding-top: 45px;
  margin-top: 0;
}

.about-leadership {
  display: flex;
}

.about-leadership-img {
  width: 25%;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-leadership-img img {
  width: 100%;
}

.about-leadership-details {
  width: 75%;
  padding: 25px;
  display: flex;
  align-items: center;
}

.about-leadership-details p {
  font-size: $pq_font_small;
  color: $pq_dark_grey;
}

.leader-social img {
  width: 40px;
  margin: 10px 10px 10px 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.leader-social img:hover {
  margin: 5px 10px 15px 0;
}
.about-leadership-details h2 {
  font-weight: $pq_regular;
  color: $pq_key_color;
}

.about-story {
  background: $pq_key_color;
}

.story-section p {
  color: $pq_support_color;
  font-weight: $pq_light;
}

.about-leadership {
  margin: 0 $pq_margin;
}

.about-story {
  padding: $pq_margin 0;
}

.send-massage{
  margin-top: 30px;
}

.our-story-title{
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 35px;
  color: white;
  font-weight: 500;
  margin-bottom: -30px;
}

.our-story{

}

.our-story-timeline{
position: relative;
max-width: 1200px;
margin: 100px auto;
}

.our-story-container{
padding: 10px 50px;
position: relative;
width: 50%;
animation: movedown 1s linear forwards;
opacity: 0;

}

.our-story-container:nth-child(odd)::before,
.our-story-container:nth-child(even)::before { //side bars
  content: '';
  background:  rgba(229, 229, 229, 0.956);
  width: 50px;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.our-story-container:nth-child(odd)::before{
  right: 0;
}
.our-story-container:nth-child(even)::before{
  left: 0;
}

.our-story-container:nth-child(odd) div::before,
.our-story-container:nth-child(even) div::before { //ball styling
  content: '';
  background: #fff;
  box-shadow:  0 0 0.5em #0d71fc;
  height: 0.8em;
  width: 0.8em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;

}

.our-story-container:nth-child(odd) div::before{
  right:-56px;
}

.our-story-container:nth-child(even) div::before{
  left:-60.5px
}

.our-story-container:nth-child(8) div::after { //bottom ball
  content: '';
  background: #fff;
  box-shadow: 0 0 0.5em #0d71fc;
  height: 0.8em;
  width: 0.8em;
  position: absolute;
  bottom:0;
  left: 0;
  margin-left: -60px;
  margin-bottom: -27px;
  transform: translateY(-50%);
  border-radius: 50%;
}

.our-story-container:nth-child(1) div::after {  //top ball 
  content: '';
  background: #fff;
  box-shadow: 0 0 0.5em #0d71fc;
  height: 0.8em;
  width: 0.8em;
  position: absolute;
  top:0;
  right: 0;
  margin-right: -56px;
  margin-top: -10px;
  transform: translateY(-50%);
  border-radius: 50%;
}





.our-story-container:nth-child(2){
animation-delay: 0.5s;
  }
.our-story-container:nth-child(3){
  animation-delay: 1s;
}.our-story-container:nth-child(4){
  animation-delay: 1.5s;
}
.our-story-container:nth-child(5){
  animation-delay: 2s;
}
.our-story-container:nth-child(6){
  animation-delay: 2.5s;
}

.our-story-container:nth-child(7){
  animation-delay: 3s;
}

.our-story-container:nth-child(8){
  animation-delay: 3.5s;
}

@keyframes movedown {
  0%{
    opacity: 1;
    transform: translateY(-30px);
  } 100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.our-story-text-box{
padding: 10px 20px;
position: relative;
border-radius: 6px;
color: rgb(230, 229, 229);
border: 1px solid rgb(231, 231, 231);
}

.our-story-text-box h2{
  font-size: 20px;
}


.our-story-container-left{
left: 0
}

.our-story-container-right{
  left: 50%
}

.our-story-timeline::after{
  content: ' ';
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: rgba(229, 229, 229, 0.956);
  top:0;
  left: 50%;
  margin-left: -3px;
  z-index:1;
  animation: moveline 3.5s linear forwards ;
}

@keyframes moveline {
  0%{
    height: 0;;
  } 100% {
    height: 100%;
  }
}


@media screen and (max-width: 600px) {
  .our-story-timeline{
    
  }
  .our-story-timeline::after{
    left: 31px;
  }

  .our-story-container{
    width: 100%;
    padding-left: 80px;
    padding-right: 25px;
  }

  .our-story-text-box{
    font-size: 13px;
  }

  .our-story-container-right{
    left:0
  }

  .our-story-container:nth-child(odd)::before,
.our-story-container:nth-child(even)::before {
  content: '';
  background: rgb(231, 231, 231);
  width: 50px;
  height: 4px;
  margin-left: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.our-story-container:nth-child(odd)::before{
  left: 0;
}
.our-story-container:nth-child(even)::before{
  right: 0;
}

.our-story-container:nth-child(odd) div::before,
.our-story-container:nth-child(even) div::before {
 
margin-left: -58px;
}

.our-story-container:nth-child(odd) div::before{
  left: 0;
}
.our-story-container:nth-child(even) div::before{
  left: 0;
}

.our-story-container:nth-child(1) div::after{
  top:0;
  left: 0;
  margin-left: -58px;
}

.our-story-container:nth-child(8) div::after{
 margin-left: -57px;
 margin-top: -5px;
}

.wispamericaTop-oklahoma{
  font-size:20px !important; 
}
}

@media screen and (max-width: 500px) {

  .wisp-wondering-heading-p{
    font-size: 20px !important;
  }
  .wisp-wondering-subheading{
    font-size: 15px !important;
  }
 
  .wisp-wondering-floormap img{
    width: 50vh !important;
  }


  .wispamericaTop-header-middle p{
    font-size:60px !important; 
  }
  .wispamericaTop-header-middle h2{
    font-size: 27px;
    margin-top: -110px !important;
    margin-left: 55px !important;

  }

  .wispamericaEventTab-number{
    font-size: 15px !important;
  }
  .wispamericaEventTab-days p{
    font-size: 15px !important;
  }


}


/* NEWS */

.news-section {
  margin: 0 $pq_margin;
  display: flex;
  padding: $pq_margin 0;
  height: 145vh;
}

.news-section-left {
  width: 75%;

}

.news-section-right {
  width: 25%;
  height: 120vh;
}

.news-item {
  display: flex;
  height: 25vh;
  margin-bottom: 5vh;
  margin-right: $pq_margin;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.news-item-img {
  overflow: hidden;
}

.news-item-img img {
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.news-item-details {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: $pq_margin;
  word-wrap: break-word;
}

.news-item-details h5 {
  margin: 0;
  font-family: $title_font_family;
  font-size: $pq_font_mid_large;
  padding-bottom: 10px;
  font-weight: $pq_regular;
  color: $pq_key_color;
}

.news-item-details p {
  margin: 0;
  font-family: $text_font_family;
  font-size: $pq_font_small;
  margin-bottom: 10px;
  overflow: hidden;
  color: $pq_dark_grey;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news-item-details span {
  font-family: $text_font_family;
  font-size: $pq_font_regular;
  padding-bottom: 10px;
  color: $pq_dark_grey;
}

.news-item-details a {
  text-decoration: none;
  font-size: $pq_font_regular;
  padding: 5px 10px;
  background: $pq_key_color;
  width: fit-content;
  color: $pq_support_color;
  border-radius: $button_border_radis;
}

.news-item-details a:hover {
  padding: 6px 9px 4px 11px;
  // transform: scale(1.05);
}
.news-item:hover {
  background: $pq_light_grey;
  cursor: pointer;
}

.news-item:hover > .news-item-details span {
  color: $pq_dark_grey;
}

.news-item:hover > .news-item-img img {
  transform: scale(1.2);
}

// .news-item:hover > .news-item-details h5 {
//   color: $pq_support_color;
// }

.news-section-right {
  background: $pq_key_color;
  position: relative;
  height: fit-content;
}

.news-comment-wrapper {
  padding: $pq_margin;
}

.news-comment-wrapper h3 {
  font-family: $title_font_family;
  font-size: $pq_font_mid_large;
  font-weight: $pq_regular;
  color: $pq_support_color;
  text-align: center;
}

.news-pagination {
  display: flex;
  justify-content: center;
}

.news-pagination-item {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $pq_key_color;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.news-pagination-item:hover {
  background: $pq_key_color;
  color: $pq_support_color;
}

.news-pagination-item.selected {
  background: $pq_key_color;
  color: $pq_support_color;
}

.comment-item {
  display: flex;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  margin-bottom: $pq_margin;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.comment-item:hover {
  background-color: $pq_support_color;
}

.comment-item:hover > .comment-item-details {
  color: $pq_key_color;
}

.comment-item-img {
  width: 35%;
}

.comment-item-img img {
  width: 100%;
}

.comment-item-details {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
  color: $pq_support_color;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.comment-item-details * {
  margin: 0;
  padding-bottom: 5px;
}

.comment-item-details h5 {
  font-size: $pq_font_regular;
  font-family: $title_font_family;
}

.comment-item-details p {
  font-size: $pq_font_regular;
  font-family: $text_font_family;
}

.comment-item-wrapper {
  text-decoration: none;
}

.news-comment-logo-wrapper {
  width: 50%;
  position: relative;
  bottom: 0;
  padding: 0 25%;
}

.news-comment-logo {
  width: 100%;
}

/* A NEWS */

.a-news-image-row {
  display: flex;
}

.a-news-image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.a-news-image-wrapper {
  padding: $pq_margin;
}

.a-news-image-row-wrapper {
  height: 30vh;
  margin: 1px;
}

.a-news-image-row-wrapper img {
  height: 100%;
  margin: 1px;
}

.a-news-image-description {
  padding: $pq_margin $pq_margin 0 $pq_margin;
  color: $pq_dark_grey;
  font-size: $pq_font_regular;
  width: 70vw;
  margin: auto;
  text-align: justify;
}

.a-news figure {
  width: 60%;
  margin: auto;
}
/* TEAM */

.team-image-container {
  display: flex;
}

.team-image-row {
  display: flex;
}

.team-image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-image-wrapper {
  padding: $pq_margin;
}

.team-image-row-wrapper {
  height: 30vh;
}

.team-image-row-wrapper img {
  height: 100%;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.team-image-row-wrapper img:hover {
  transform: scale(1.1);
}

.team-header{
  display: flex;
  flex-direction: column;
  height: 45vh!important;
}

//commented changes

// .team-image-description {
//   padding: 0 $pq_margin 0 150px;
//   display: flex;
//   justify-content: center;
// }

// .team-image-desc {
//   width: 35vw;
//   display: flex;
//   margin-right: 15px;


// }

// .team-image-desc p {
//   box-shadow: 0 3px 7px rgba(0, 0, 0, 0.205);
//   line-height: 1.5em;
//   height: fit-content;
//   text-align: justify;
//   margin: auto;
//   font-size: $pq_font_regular;
//   background-color:white;
//   padding: 20px;
//   border-radius: 5px;
//   margin-top: -36px;
//   font-weight: 500;
// }

// .team-image-img {
//   display: flex;
//   align-items: center;
//   margin-left: 110px;
//   margin-top: -200px;
// }

// .team-image-img img {
//   height: 55%;

// }


// New changes for team page

.team-image-description {
  // padding: 0 $pq_margin 0 150px;
  display: flex;
  justify-content: center;
}

.team-image-desc {
  display: flex;
  margin-right: 15px;
}

.team-image-desc p {
  line-height: 1.5em;
  text-align: center;
  margin: auto;
  width: 1000px;
  font-size: $pq_font_regular;
  margin-top:-40px;
  color: $pq_support_color;
}

.team-image-img {
  display: flex;
  align-items: center;
  margin-left: 110px;
  margin-top: -200px;
}

.team-image-img img {
  height: 55%;
}

.team-hr {
  height: 100%;
  display: flex;
  align-items: center;
}

.team-hr .vertical-hr {
  color: $pq_key_color;
  opacity: 1;
}

/* DEMO */
.demo-container {
  background: $pq_key_color;
  display: flex;
  height: 50vh;
}

.demo-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.demo-img {
  width: 60%;
  height: 80%;
}

.demo-img img {
  height: 100%;
}

.demo-left {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#demoBtn:hover {
  opacity: 0.5;
}
.demo-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
}

.demo-button h1 {
  color: $pq_support_color;
  font-weight: $pq_light;
  margin-top: 0;
}

.demon-btn-container {
  width: 100%;
}

.demon-btn-container .btn {
  margin-left: auto;
  margin-right: 0;
}

// Terms of Service

.product-terms-continer {
display: flex;
justify-content: center;
background-color: #EDEEFA;
border-radius: 10px;
margin: 80px 120px;
padding-right: 20px;
padding-bottom: 30px;
}

.product-terms-left{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-top: 120px;
  padding-left: 10px;
}

.product-terms-right{
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 20px;
}

.product-terms-left p {
  color: $pq_key_color;
  font-weight: 600;

}

.product-terms-right h1{
  font-weight: 800;
  font-family: 'Montserrat';
  color: $pq_key_color;
  margin-top: 45px;
}

.product-terms-right h2{
  font-weight: 600;
  color: $pq_key_color;
  font-size: 22px;
  margin-bottom: -2px;
}

.product-terms-right p{
  text-align: justify;
}

.product-terms-vl{
  width: 3px;
  align-self: stretch;
  background-color: #D5D6F2; 
  margin: 20px; 
}

.product-terms-right-bottom{
  font-style: italic;
}


/* Download */
.product-download {
  height: 50vh;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.product-download img {
  width: 60%;
}

.product-download-banner {
  width: 30%;
  opacity: 0.2;
}

.product-download-button {
  width: 40%;
  text-align: center;
}



.product-download-button:hover {
  cursor: pointer;
}

/*usecases*/

#usecase-intro {
  margin: auto;
}

.usecase-header{
  background: url('../public/assets/useCases/usecases.jpg')!important;
  background-position: center !important;
  background-size: cover !important;
}

.usecase-header p {
  text-align: center;
}


#usecase-intro p {
  font-size: $pq_font_regular;
  font-weight: $pq_light;
  color: $pq_key_color;
  padding-top: $pq_margin;
}
.usecase-vector {
  margin: auto;
  text-align: center;
}
.usecase-vector img {
  width: (4 * $pq_margin);
  padding-bottom: $pq_margin;
  margin: auto;
}

.usecase-iconlist {
  display: flex;
  background-color: $pq_key_color;
  height: 25vh;
  padding: 0 $pq_margin;
}

.usecase-icon {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.usecase-icon img {
  height: 10vh;
  color: $pq_support_color;
}

.usecase-icon a {
  text-decoration: none;
}

.usecase-name {
  color: $pq_support_color;
  margin-top: 2px;
  text-align: center;
}

.usecase-icon-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  gap:70px;
  margin-top: 150px;
}

.usecase-icon-button{
  background-color: $pq_key_color;
  padding: 15px 40px 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.3); 
}

.usecase-icon-button img{
  width: 100px;
}

.usecase-icon-button:hover{
  background-color: #0d2841e0;
  
}




/*case_studies*/
#case-studies-vector {
  text-align: center;
  margin: auto;
}

#case-studies-vector p {
  font-size: $pq_font_regular;
  font-weight: $pq_light;
  color: $pq_key_color;
  padding-top: $pq_margin;
}

#case-studies-vector img {
  width: (3 * $pq_margin);
  padding-bottom: $pq_margin;
  margin: auto;
}

#case-studies-sec {
  background-color: $pq_support_color;
  height: auto;
  padding: 10px 120px;
}

#case-studies-item {
  display: flex;
  padding-top: $pq_margin;
}

#case-studies-title {
  height: auto;
  width: 60%;
  text-align: left;
}

#case-studies-title p {
  font-size: $pq_font_mid_large;
  font-weight: $pq_regular;
  color: $pq_key_color;
  margin-bottom: $pq_margin;
}
#case-studies-title a {
  padding: 10px 30px;
  font-size: 22px;
  font-weight: 500;
  color: $pq_key_color;
  background-color: $pq_support_color;
}
#case-studies-img {
  width: 40%;
  height: 40%;
  min-width: 400px;
}

#case-study {
  margin: 2em 5em;
  font-family: $text_font_family;
}
#case-study strong {
  color: $pq_key_color;
}

.case-study-component {
  padding: ($pq_margin / 2) $pq_margin;
  height: 25vh;
  position: relative;
  margin: 0 (3 * $pq_margin);
}

.case-study-components {
  margin-bottom: $pq_margin;
}

.case-study-component.hover {
  // background: $pq_key_color;
  background-color: $pq_light_grey;
  cursor: pointer;
}

.case-study-content {
  display: flex;
  height: 100%;
}

.case-study-image img {
  height: 100%;
}

.case-study-component hr {
  width: 60%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  color: $pq_key_color;
  opacity: 0.5;
}

.case-study-description {
  color: $pq_key_color;
  width: 50%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// .case-study-description.hover {
//   color: $pq_support_color;
// }

.case-study-image {
  width: 50%;
  text-align: right;
}

.case-study-btn-container div {
  margin-left: 0;
}

.study-container {
  padding: $pq_margin;
}

.study-container h2 {
  font-size: $pq_font_regular;
  font-weight: $pq_semibold;
  color: $pq_key_color;
  font-family: $title_font_family;
  margin-bottom: ($pq_margin/2);
}

.study-container p {
  color: $pq_dark_grey;
  font-family: $text_font_family;
  font-size: $pq_font_regular;
  text-align: justify;
}

.study-section {
  margin-bottom: $pq_margin;
}

.case-studies-header {
  height: 35vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../public/assets/caseStudies/mainBanner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

}

.case-studies-bgoverlay {
  position: relative;
  width: 100%;
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0d2841b3; 
  color: white; 
}

.case-studies-header h4 {
  color: $pq_support_color;
  font-size: $pq_font_large;
  font-weight: $pq_regular;
  width: 100%;
  text-align: center;
}

.case-studies-header p{
  color:$pq_support_color;
  margin-top: -50px;
}

.case-studies-cards{
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  padding: 50px;
  margin-top: 30px;
  width: 100%;
  gap: 8%; 
}

.casestudy-cards-wrapper{
  // width: 25%;
  width:370px;
  background-color:#0d2841;
  margin-bottom: 70px;
  border-radius: 3px;
}

.casestudy-cards-wrapper img {
  width: 100%;
}

.casestudy-cards-wrapper p {
  color: $pq_support_color;
  margin: 20px;

}

.casestudy-btn{
  margin: 20px;
  background-color: #0d2841;
  border: 1px  solid white;
  color: white;
  padding: 5px 10px !important;
  cursor: pointer;
  padding: 10px;
  font-weight: 500;
  font-size: 15px;
  border-radius: 3px;

}

// use_cases_item
.use-case-item {
  font-family: $text_font_family;
  background: #fff;
  padding: $pq_margin;
  position: relative;
}



.use-case-item:hover h3 {
  color: $pq_light_blue;
}

.use-case-desc strong {
  color: $pq_key_color;
}
.use-case-desc p {
  font-size: $pq_font_regular;
  line-height: 1.5em;
  font-family: $title_font_family;
  color: $pq_dark_grey;
  text-align: justify;
}

.usecase-downloads {
  height: 35vh;
  display: flex;
  align-items: center;
  margin-top: -40px;
}

.usecase-downloads a {
  width: 100%;
  text-decoration: none;
}

.a-use-container {
  padding: $pq_margin;
  line-height: 1.5em;
  color: $pq_dark_grey;
  text-align: justify;
  font-family: $title_font_family;
  font-size: $pq_font_regular;
}


/********** EVENTS ****************/
.events-ongoing{
  display: flex;
  align-items: center;
  font-size: 22px;
  font-family: 'montserrat';
  font-weight: 900;
  color:$pq_darker_key;
  margin-left: 180px;
  margin-top: 80px;
  margin-bottom: 50px;

}
.events-title-pre{
  height: 30px;
  background-color: rgb(165, 165, 165);
  padding: 3px;
  margin-right: 10px;
}

.events-header{
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../public/assets/events/events-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 60px;
}

.events-bgoverlay{
  position: relative;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(13, 40, 65, 0.527);; 
  color: white; 
  mix-blend-mode: hard-light;
}

.events-header h4 {
  color: $pq_support_color;
  font-size: $pq_font_large;
  font-weight: 600;
  width: 100%;
  text-align: center;
  font-family: 'montserrat';

}

.events-header p{
  color:$pq_support_color;
  margin-top: -52px; 
  text-align: center;
  font-weight: 500;
}

.events-card-image{
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  padding: 3px;
}

.events-card-image .events-card-img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #4070f4
}

.events-slide-container{
margin-top: 20px;
margin-bottom: 20px;
max-width: 1120px;
width:100% ;
}

.events-card{
  border-radius: 15px;
  background-color: #0f273e;
  width: 320px;
}

.events-slide-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 44px;
}
.events-image-content .events-card-content{
padding: 10px 14px;
display: flex;
flex-direction: column;
align-items: center;
}

.events-image-contente::before{
content: '';
position: absolute;
height: 1;
width:auto;
top:o;
left: 0;
background-color: red;
z-index: 1000;
}

.events-card-content h2{
  font-size: 18px;
  font-weight: 600;
  color: rgb(254, 254, 254);
  margin-top: 50px;
}

.events-card-overlay{
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  left: 0;
  top: 0;
  height: 60%;
  width: 100%;
  border-radius: 14px 14px 0px 0px;
}



.events-card-latest{
  padding: 2px 10px;
  background-color: red;
  width: fit-content;
  margin: 20px;
  color:white;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  font-family: 'montserrat', sans-serif;
}

// .events-card-overlay::before,
// .events-card-overlay::after{
//   content: 'dsfd';
//   position: absolute;
//   top:0;
//   left:0;
// }

.events-image-content{
  position: relative;
  row-gap: 5px;
}

.events-card-button{
  cursor: pointer;
  border: 1px solid white;
  background-color: #0D2841;
  font-size: 16px;
  color: white;
  padding: 4px 16px;
  border-radius: 6px;
  margin:14px;
  margin-top: -1px;
  transition: all 0.3 ease;
}

.events-card-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.events-card-button:hover{
  background-color:#0e2e4dd9;
}



.current-event{
  margin: 80px 170px;
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: center;
}

.current-event-image-container {
  position: relative;
  width: 100%; 

}



.current-event-image-container img {
  width: 100%;
  background-position: cover;
  display: block;
  border-radius: 23px;
  width: 532px;
  height: 286px;
}

.current-event-img-overlay{
  position: absolute;
    top: 0;
    left: 0;
    width: 532px;
    height: 286px;
    background: linear-gradient(180deg, rgba(14, 43, 70, 0.108) 0%, rgba(92, 111, 128, 0.64) 23.4%, #0d284182 39.4%, #0d2841ea 68.4%);
    background-blend-mode: plus-darker;
    mix-blend-mode: hard-light;
    border-radius: 23px;
    
}


.current-event-wisp-logo{
  position: absolute;
  z-index: 200;
  width: 50% !important;
  height: auto !important;
  bottom: 15px;
  left: 130px ;

}
.current-event-hr{
width: 1px;
height: 275px;
border: 1.57px solid rgba(53, 53, 53, 0.64);
margin-left: 40px;
margin-right: 30px;
}

.current-event-right{
  display: flex;
  gap: 10px;

}
.current-event-left{
height: 286px;
}

.current-event-right-content-upper{
  display: flex;
  align-items: center;
  margin-bottom: -25px;
}


.current-event-right-content-upper-date{
  font-size: 16px;
  font-weight: 600;
  font-family: 'montserrat';
}
.current-event-right-dot{
  margin-left: 16px;
  margin-right: 8px;
width: 6px;
height: 6px;
background: #3B3B3B;
border-radius: 50%;
}
.current-event-right-content-upper-booth{
  font-size: 16px;
  font-weight: 600;
  font-family: 'montserrat';
  color: red;
}
.current-event-right-content-des{
  text-align: justify;
  font-size: 14px;
  font-weight: 500;
  font-family: 'montserrat';
  
}

.current-event-right-content-title{
  font-family: 'montserrat';
  font-weight: 900;
  color: rgba(0, 0, 0, 0.9);
  font-size: 25px;
  margin-bottom: -5px;
}

.current-event-more-info-btn {
  background: #0A2238;
  border: 2px solid #0D2841;
  border-radius: 5px;
  color: white;
  font-family: 'montserrat';
  font-size: 12px;
  padding: 8px 22px;
  font-weight: 600;
  cursor: pointer;

}


.current-event-more-info-btn:hover{
  font-weight: 800;
}


//###################### webinar-page ################

.webinar-header{
  margin-top: 60px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  padding-top: 40px;
  height: 40vh;
}
.webinar-header-live{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;

}
.webinar-header-live img{
  width: 12px;
  height: 12px;
  margin-right: 10px;
  
}
.webinar-header-live p{
  font-family: 'montserrat';
  font-weight: 700;
  font-size: 12px;
  color: #0D2841;
}

.webinar-header-title{
  color:#0D2841;
  font-size: 25px;
  font-family: 'montserrat';
  margin-bottom: 0px;
}

.webinar-header-subtitle{
  color:#0D2841;
  font-family: 'barlow';
  font-size: 10x;
}

.webinar-banner{
  position: relative;
  background-image: url('../public/assets/webinars/images/banner background.jpg');
  height: 65vh;
  background-position: center;
  background-size: cover;
  
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  pointer-events: none; 
  
}

.gradient-top {
  z-index: 1;
  background:  linear-gradient(90deg, rgba(0, 0, 0, 0.786) 25.47%, rgba(102, 102, 102, 0.74) 96.89%);
}

.gradient-bottom{
  z-index: 2;
 background:  linear-gradient(179.8deg, rgba(12, 38, 62, 0.89) 0.17%, rgba(33, 103, 167, 0.89) 90.1%);
mix-blend-mode: hard-light;
}


.webinar-banner-content{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding-left: 150px;
  padding-top: 50px;
 
}

.webinar-banner-content-live{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.webinar-banner-content-live p{
font-weight: 700;
font-size: 12px;
color: white;
}

.webinar-banner-content-live img{
  width: 12px;
  margin-right: 8px;
  }


.webinar-banner-content-title{
  font-family: 'Montserrat';
  font-size: 30px;
line-height: 49px;
letter-spacing: 0.01em;
color: white;
z-index: 4;
margin-bottom: 10px;
margin-top: 0px;
}

.webinar-banner-content-subtitle{
  font-size: 18;
  font-family: 'barlow';
  color: rgba(255, 255, 255, 0.764);
  z-index: 4;
  margin-bottom: 30px;
}

.webinar-banner-content-datetime{
margin-left: 20px;
z-index: 4;
}


.webinar-banner-content-hr{
  width: 406px;
  opacity: 0.67;
border: 1px solid #FFFFFF;
z-index: 4;
margin-bottom: 12px;
}

.webinar-banner-content-date{
  z-index: 4;
  font-family: 'barlow';
  font-size: 18px;
  color: white;
  margin-bottom: 18px;
  letter-spacing: 0.03em;
}

.webinar-banner-content-register{
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  color: #0D2841;
  font-weight: 700;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  letter-spacing: 0.01em;
  margin-top: 20px;
}

.webinar-flyer{
  display: flex;
  justify-content: center;

  margin-top: 50px;
  margin-bottom: 50px;

}
.webinar-flyer-post{
  display:flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.webinar-flyer-post img{
  width: 60%;
}
.webinar-flyer-content{

  display: flex;
  flex:1;
  justify-content: flex-start;
  flex-direction: column;
  gap:5px;

}

.webinar-flyer-content-title{
 
  font-family: 'montserrat';
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
}

.webinar-flyer-content-subtitle{
  font-family: 'montserrat';
  font-size: 15px;
}

.webinar-flyer-content-resource{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap:10px;
  padding: 20px;
}

.webinar-flyer-content-resource-1{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  font-size: 17px;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;

}

.webinar-flyer-content-resource-1 img{
  width: 50%;
  
}

.webinar-flyer-content-resource-2{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  font-size: 17px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.webinar-flyer-content-resource-2 img{
  width: 50%;

}



.webinar-flyer-content-resource-1-title p{
  text-align: center;
  line-height: 1.5;
}









@media only screen and (max-width: 1400px) {
  .current-event{
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }

  .current-event-hr{
display: none;
    }

    .current-event-right-content{
      min-width: 500px;
    }

}

@media only screen and (max-width: 557px) {
  .current-event-image-container img {
    border-radius: 15px;
    width: 80vw;
    height: 45vw;
  }

  .current-event-img-overlay{
    top: 0;
    left: 0;
    width: 80vw !important;
    height: 45vw !important;
    border-radius: 15px;
  }

  .current-event-wisp-logo{
    bottom: 15px;
    left: 26% ;
  
  }

  .current-event-right-content {
    min-width: 0px;
    width: 80vw !important;
    height: 45vw !important;
}

.current-event-right-content-title{
  font-size: 25px;
  margin-bottom: -5px;
}

.current-event-right{
  gap: 0px;
  margin-bottom: 40vw;
}

}

@media only screen and (max-width: 400px) {

  .wisp-wondering-heading-p{
    font-size: 17px !important;
  }
  .wisp-wondering-subheading{
    font-size: 14px !important;
  }
 
  .wisp-wondering-floormap img{
    width: 40vh !important;
  }

  .wisp-wondering-timedata{
    padding: 0px 0px !important;
  }


  .current-event-right-content{
  margin-top: -100px;
  margin-bottom: 50px;
  }
  
  .current-event-right-content-des{
    font-size: 14px ;
  }
  
  }


  @media only screen and (min-width: 1600px) {

    .current-event{
      margin: 5% 15%;
}
  
  }





.events-cardCarousel{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 120px;
}
.events-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 1500px;
  position: relative;
}

.events-carousel-wrapper {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease-out;
}

.events-carousel-card {
  flex: 0 0 33.333%;
}

.events-carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.events-left-btn {
  left: 1px;
}

.events-right-btn {
  right: 1px;
}

.events-hidden {
  display: none;
}

@media (max-width: 768px) {

  .header-mobile-logo img {
    height: 40% !important;
  }
  .events-carousel-card {
    flex: 0 0 100%; 
  }
.events-cardCarousel{
  margin: 70px 10px;
}

.widi-time-box{
  min-width: 20vh !important;
}

.wispamerica-map{
  
}
 .wispamerica-floormap{
    margin-top: 150px !important;
    margin-right: 0px  !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: auto !important;
  }

}

@media (max-width: 450px) {
  .events-carousel-card {
    flex: 0 0 100%; 
  }
.events-cardCarousel{
  margin: 70px 10px;
}

.wispamericaEventTab-addtoCalendar{
  display: none;
}
.wispamericaTop-container img{
  z-index: 100;
 margin-top: 140px !important;
}

.wispamericaTop-text{
  padding-top: 30px!important;
}

.Wispamerica-widistandout{
  margin-top: -60px !important;
}
.Wispamerica-widistandout-title{
  font-size: 20px !important;
  margin-bottom: 30px;
}
.Wispamerica-widistandout-subtitle{
font-size: 15px !important;
}
.Wispamerica-widistandout-discription{
font-size: 10px !important;
}
}








/* Cookie Banner */
.CookieConsent {
  justify-content: flex-start !important;
}

.CookieConsent div {
  font-size: $pq_font_small !important;
  flex: none !important;
}

#rcc-confirm-button {
  background: $pq_embedded_color !important;
  padding: 10px 15px !important;
  font-family: $text_font_family !important;
}

/* Datasheets */
.datasheets {
  display: flex;
  flex-direction: column;
}

.datasheets-header {
  height: 35vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../public/assets/datasheets/datasheet.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.datasheets-bgoverlay{
  position: relative;
  width: 100%;
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0d2841b3; 
  color: white; 
}

.datasheets-header h4 {
  color: $pq_support_color;
  font-size: $pq_font_large;
  font-weight: $pq_regular;
  width: 100%;
  text-align: center;
}

.datasheets-header p{
  color:$pq_support_color;
  margin-top: -50px;
}


.datasheets-upper-container{
  display: flex;
  justify-content: center;
}

.datasheet-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  margin-top: 60px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.184);
  border-radius: 5px;
  padding: 50px 70px 50px 70px;
}

.datasheet-label-container label {
  font-weight: 500;
  font-size: 17px;
}


.datasheet-labels .datasheet-label-container {
  color: $pq_dark_grey;
  font-size: $pq_font_regular;
  font-weight: $pq_regular;
  height: calc(5vh - 5px);
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.datasheet-inputs {
  display: flex;
  flex-direction: column;
  width: 60%;
}

//######################

.datasheet-input-box {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 10px;
}

.icon {
  position: absolute;
  padding: 10px;
  color: $pq_darker_key;
  min-width: 50px;
  text-align: center;
}

.datasheet-textbox {
  width: 100%;
  padding: 10px;
  text-align: left;
  padding-left: 55px; 
  border-radius: 5px;
  border: 1.5px solid $pq_darker_key;
  font-size: 15px;
}

.datasheet-textbox:hover {
border: 1px solid #0d284183;
}



.datasheet-inputs input {
  height: calc(5vh - 10px);
  margin: 5px 0;
  color: $pq_key_color;
  font-family: $text_font_family;
  border: 1px solid $pq_dark_grey;
}

.datasheet-unconfirmed {
  padding: $pq_margin;
  margin-top: -40px;
}

.datasheet-error {
  text-align: center;
  color: $pq_key_color;
  margin: 5px;
  font-size: $pq_font_small;
}

.datasheet-confirmed {
  display: flex;
  flex-wrap: wrap;
  margin: 0 ($pq_margin * 3);
}

.datasheet-downloader {
  flex: 1 0 30%;
  margin: 20px 5px;
}

.datasheet-download-wrapper {
  text-decoration: none;
}

.datasheet-download-item {
  width: 20vw;
  height: 20vw;
  background: $pq_light_grey;
  margin: auto;
  text-align: center;
  position: relative;
}

.datasheet-download-item img {
  height: 45%;
  margin-top: 20px;
}

.datasheet-download-item:hover > .datasheet-download-btn {
  background: $pq_key_color;
  color: $pq_support_color;
}

.datasheet-download-btn {
  position: absolute;
  bottom: 0;
  background: $pq_light_grey;
  height: 15%;
  width: 100%;
  color: $pq_key_color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datasheet-download-btn p {
  margin: auto;
}

.datasheet-product {
  color: $pq_dark_grey;
}

/* Request Demo page*/
.demo-header {
  text-align: center;
  margin: 0 $pq_margin;
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.demo-wrapper {
  width: 70%;
}

.demo-wrapper p {
  font-family: $title_font_family;
  font-size: $pq_font_regular;
  font-weight: $pq_regular;
  color: $pq_dark_grey;
}

.demo-wrapper h1 {
  font-family: $title_font_family;
  font-size: $pq_font_large;
  font-weight: $pq_regular;
  color: $pq_key_color;
}

.demo-section {
  margin: $pq_margin $pq_margin; 
  width: 90%;
  display: flex;
}


.demoshape{
  height: 15vw;
}

.demoshape img{
  // transform: rotate(180deg);
  object-fit: contain;
}
.demo-ss-wrapper{
  width: 50%;

}
.demo-form {
  width: 50%;
}

.demo-form p {
  text-align: center;
  color: $pq_key_color;
}

.demo-form form {
  display: flex;
  flex-direction: column;
}

.demo-form  input, .demo-form  select, .demo-form textarea{
  margin: 10px 5px;
  padding: 10px;
  font-family: $title_font_family;
  width: -webkit-fill-available;
}
.demo-form form input{
  border: 1px solid $pq_light_grey;
}
.demo-form form textarea {
  border: 1px solid $pq_light_grey;
  height: 100px;
}


.demo-form  .row {
  overflow: hidden;
}
.demo-form  .col {
  float: left;
  width: 50%
} 

.demo-form h2 {
  font-weight: $pq_regular;
  color: $pq_key_color;
  margin-top: 0;
  margin-bottom: 30px;
}

.demo-ss { 
	width:90%;
	height:86%;
	position:relative;	
  background-color:rgb(13, 33, 59);
  display: flex;
  justify-content: center;
}
.demo-ss img {
  width: 100%;
  max-height: 100%;
  padding: 05%;
  object-fit: contain;
}

#demo-img-1 { 
	transform: rotate(7.0167096047110005e-15deg);
	width:90%;
	height:auto;
	position:absolute;
	background-repeat:no-repeat;
	background-size:cover;
}
#demo-img-2 { 
	width:369px;
	height:204px;
	position:absolute;
	left:25px;
	top:225px;
	background-repeat:no-repeat;
	background-size:cover;
}
#demo-img-3 { 
	width:410px;
	height:218px;
	position:absolute;
	left: 180px;
	top:75px;
	background-repeat:no-repeat;
	background-size:cover;
}

.demo-form lable {
  font-size: $pq_font_small;
}
.demo-error{
  color: red;
}
/*book a demo*/
@media only screen and (max-width: 1020px) {

  .wispamericaEventTab{
    position: absolute;
    top: 75% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .wispamericaEventTab img, .wispamericaEventTab-location, .wispamericaEventTab-date1, .wispamericaEventTab-svg  {
   display: none;
  }

  .wispamericaEventTab-tab{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    background-color: white;
    width: 80%;
    height: 90px;
    border-radius: 9px;
    box-shadow: 0px -5px 60px -3px rgba(0, 0, 0, 0.25);
  }

  .wispamericaTop-oklahoma{
    font-size:70px !important;  
  }

  


.demo-header {
  margin-top: 30%;
  }  .demo-section {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .demo-form{
      width: 90%;
    }
    .demo-form h2 {
      margin: auto;
      width: fit-content;
    }
    .demo-form .col {
      float: left;
      width: 100%;
  }
    .demo-section form{
      width: 80%;
      margin: auto;
    }
    .demo-ss-wrapper {
      display: none;
    }

    .indicator-item.selected img {
      height: 12px;
      margin-bottom: -8px !important;
    
    }

    .product-terms-continer {
      margin: 80px 40px;
    }

  }
/* MOBILE */
@media only screen and (max-width: 1020px) {
  /* HEADER */
  .header {
    display: none;
  }

  .page {
    margin-top: 0;
  }

  .header-mobile {
    display: block;
    height: $pq_mobile_header_height;
    position: absolute;
    width: 100%;
    background-color: $pq_key_color !important;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .header-mobile.transparent {
    background: transparent;
  }

  .header-mobile.expanded {
    background: transparent;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .header-mobile .hamburg {
    margin-top: 15px;
    width: 50px;
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-mobile.expanded .header-mobile-logo {
    height: 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .hamburg span {
    background: $pq_support_color;
    border-radius: 10px;
    height: 3px;
    margin: 3px 10px;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .hamburg span:nth-of-type(1) {
    width: 40%;
  }

  .hamburg span:nth-of-type(2) {
    width: 70%;
  }

  .hamburg.expanded span:nth-of-type(2) {
    transform-origin: top;
    transform: rotateZ(45deg);
    width: 60%;
  }

  .hamburg.expanded span:nth-of-type(1) {
    width: 0%;
  }

  .hamburg.expanded span:nth-of-type(3) {
    transform-origin: center;
    transform: rotateZ(-45deg) translateY(-7px) translateX(6px);
  }

  .header-mobile-dropdown {
    width: 100%;
    height: 10%;
    position: fixed;
    background: $pq_key_color;
    z-index: 1;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .header-mobile-dropdown .header-mobile-content {
    display: none;
  }

  .header-mobile-dropdown.transparent {
    background: transparent;
  }

  .header-mobile-dropdown.expanded {
    height: auto;
    // -webkit-clip-path: polygon(0 0, 0 85%, 100% 130%, 100% 0%, 75% 0);
    // clip-path: polygon(0 0, 0 85%, 100% 130%, 100% 0%, 75% 0);
    transition: 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    background: $pq_key_color;
    padding-bottom: 5vh;
    min-height: 80vh;
  }



  .header-mobile-logo {
    text-align: center;
    height: 10vh;
    width: calc(100% - 100px);
    display: flex;
    justify-content: center;
    margin-left: 50px;
    align-items: center;
    transition: 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .header-mobile-logo img {
    height: 80%;
  }

  .header-mobile-logo a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-mobile-dropdown.expanded .header-mobile-logo img {
    height: 0%;
  }

  .header-mobile-dropdown-logo {
    height: 0%;
    text-align: right;
    bottom: 0;
    position: absolute;
    right: 0;
  }

  .header-mobile-dropdown.expanded .header-mobile-dropdown-logo {
    height: 5%;
    text-align: right;
    bottom: 0;
    position: absolute;
    right: 0;
  }

  .header-mobile-content {
    opacity: 0;
    transition: 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .header-mobile-dropdown.expanded .header-mobile-content {
    display: block;
    opacity: 1;
    transition: 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .header-mobile-dropdown-logo img {
    height: 100%;
  }

  .header-mobile-dropdown.expanded .header-mobile-dropdown-logo img {
    height: 100%;
  }

  .header-mobile-content-types {
    display: flex;
    width: 100%;
    padding-top: 17px;
  }

  .header-mobile-content-types img {
    width: 25%;
    padding: 6%;
  }

  .header-mobile-content-externalLinks{
    display: flex ;
    align-items: center;
    justify-content: space-around;
    margin: 10px;
    gap:9px;
    

  }

  .header-mobile-content-externalLinks p {
    color: white;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 5px;
    font-size: 17px;
    margin-left: 7px;
    
  }

  .header-mobile-content-externalLinks-link{
    display: flex;
      background-color: rgba(248, 248, 248, 0.079);
      border-radius: 7px;
      padding-top: 1px;
      padding-left: 5px;
      padding-right: 10px;
  }

 

 

  .header-mobile-content-section {
    margin: 0 $pq_margin;
  }

  .header-mobile-content-section h5 {
    margin: 1em 0 0.2em 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: $pq_support_color;
  }

  .header-mobile-content-section .row {
    display: flex;
  }

  .header-mobile-content-section .row a {
    width: 50%;
    padding: 10px 0;
    color: $pq_support_color;
    text-decoration: none;
    font-family: $text_font_family;
  }

  .header-mobile-content-section.hidden {
    display: none;
  }

  /* FOOTER */
  .footer {
    height: 100vh;
    background: $pq_darker_key;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-container {
    width: 100%;
    margin: 0 5%;
    text-align: center;
  }

  .footer-container img {
    width: 50%;
  }

  .footer-contact {
    display: flex;
    color: $pq_support_color;
    font-family: $text_font_family;
    font-size: $pq_font_small;
    justify-content: center;
  }

  .footer-email {
    width: 100%;
    text-align: right !important;
  }

  .footer-filler {
    width: 10%;
    text-align: center;
  }

  .footer-number {
    width: 100%;
    text-align: left;
  }

  .footer-address {
    margin-top: $pq_margin;
    color: $pq_support_color;
    font-size: $pq_font_small;
    font-family: $title_font_family;
  }

  .footer-address p {
    margin: 5px 0;
  }

  .footer-social {
    display: flex;
    justify-content: center;
    margin: $pq_margin;
  }

  .footer-links {
    color: $pq_support_color;
  }

  .footer-links a {
    text-decoration: underline;
    color: $pq_support_color;
    font-size: $pq_font_small;
    padding: 10px;
  }

  /* HOME */

  /* HOME-BANNER */
  .home-banner {
    height: auto;
  }

  .home-banner img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .banner-web {
    display: none;
  }

  .banner-mobile {
    display: block;
    height: 100vh;
  }

  .banner-indicator {
    display: flex;
    position: absolute;
    bottom: 10px;
    height: 10%;
    width: 100%;
    justify-content: center;

  }

  .indicator-item {
    width: 10px;
    margin: 10px;
    transition: 0.5s;
    padding-top: 4px;

  }

  .indicator-item.selected {
    width: 20px;
    transition: 0.5s;
    padding-top: 12px;
  }

  .indicator-item img {
    object-fit: contain;
    height: 100%;
  
  }

  .banner-img {
    width: 0% !important;
    transition: 1s;
  }

  .banner-img.selected {
    width: 100% !important;
    transition: 1s;
    
  }

  .banner-title {
    position: absolute;
    color: $pq_support_color;
    text-align: center;
    top: 30%;
    width: $pq_banner_mobile;
    font-family: $title_font_family;
  }



  .banner-title h1 {
    font-weight: $pq_regular;
  }


  .banner-title-o h1{
    position: absolute;
    color: $pq_support_color;
    text-align: center;
    top: 25%;
    width: $pq_banner_mobile;
    font-family: $title_font_family;
  }

  .banner-subtitle {
    position: absolute;
    color: $pq_support_color;
    text-align: center;
    top: 60%;
    width: $pq_banner_mobile;
    padding: 0 $pq_margin;
    font-family: $text_font_family;
    font-weight: $pq_light;
    left: 0;
  }

  .banner-button {
    position: absolute;
    color: $pq_support_color;
    text-align: center;
    bottom: 20%;
    width: 100%;
    top: auto;
    left: auto;
    padding: unset;
    background: none;
  }

  .banner-button a {
    padding: 10px 20px;
    background: $pq_key_color;
    color: $pq_support_color;
    text-decoration: none;
  }

  .banner-button:hover {
    // font-weight: 600;
    opacity: 0.5;
  }
  /* HOME CATEGORIES */

  .home-categories {
    height: 100vh;
    margin: 0 $pq_margin;
  }

  .home-title {
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .home-title h1 {
    font-size: $pq_font_mid_large;
    text-align: center;
    color: $pq_key_color;
    font-weight: $pq_regular;
  }

  .home-title p {
    font-size: 0.8em;
    text-align: center;
    color: $pq_dark_grey;
  }

  .home-category {
    height: 25vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: inherit;
    padding-top: 0;
  }

  .home-category:hover p {
    color: $pq_dark_grey;
  }

  .home-category.mid {
    flex-direction: unset;
  }

  .home-category-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .home-category-text.left {
    text-align: left;
  }

  .home-category-text.right {
    text-align: right;
  }

  .home-category-text h2 {
    margin: 5px 0;
    font-size: 1em;
    color: $pq_key_color;
    font-weight: $pq_regular;
  }

  .home-category-text p {
    margin: 5px 0;
    font-size: 0.8em;
    font-family: $text_font_family;
    color: $pq_dark_grey;
  }

  .home-category-icon {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 15px;
  }

  .home-category-icon img {
    width: 90%;
  }

  .home-category-container {
    margin: 0;
  }

  /* HOME INFOGRAPH */

  .home-infograph {
    height: 100vh;
    background: $pq_key_color;
  }

  .home-info-hud {
    display: block;
    position: relative;
  }

  .home-info-hud-web {
    display: none;
  }

  .home-info-hud-graph {
    position: absolute;
    top: 35vh;
    height: 30vh;
  }

  .home-info-hud-selected {
    opacity: 1;
    transition: 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .home-info-hud-hidden {
    opacity: 0;
    transition: 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .home-info-row {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-info-content {
    width: 100%;
    padding: 10px;
  }

  .home-info-row:nth-child(2) {
    margin-left: 10vw;
    border-bottom: 1px solid #ffffff69;
  }

  .home-info-row:nth-child(3) {
    margin-left: 25vw;
  }

  .home-info-row:nth-child(4) {
    margin-left: 40vw;
    border-bottom: 1px solid #ffffff69;
    border-top: 1px solid #ffffff69;
  }

  .home-info-row:nth-child(5) {
    margin-left: 25vw;
    border-bottom: 1px solid #ffffff69;
  }

  .home-info-row:nth-child(6) {
    margin-left: 10vw;
  }

  .home-info-row-title {
    margin: 0;
    color: $pq_light_grey;
    font-weight: $pq_regular;
  }

  .home-info-row-description {
    color: $pq_support_color;
    font-size: 0.8em;
    font-family: $text_font_family;
  }

  /* HOME FEATURES */

  .home-features {
    height: 100vh;
    display: flex;
    flex-direction: row;
  }

  .home-features-sidebar {
    width: 25%;
    height: 100%;
    background: $pq_key_color;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-feature-product {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $pq_key_color;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .home-feature-product.selected {
    background: $pq_support_color;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .home-feature-product img {
    width: 70%;
  }

  .home-features-content-mobile {
    display: block;
    width: calc(70%);
    margin-right: $pq_margin/2;
    margin-left: $pq_margin/2;
  }

  .home-features-content-mobile h3 {
    font-weight: $pq_regular;
  }

  .home-features-content-web {
    display: none;
  }

  .home-features-product-name {
    margin-top: 10vh;
    text-align: center;
  }

  .home-features-video {
    height: 25vh;
  }

  .home-features-list {
    height: 60vh;
    overflow: auto;
    padding-top: 10px;
  }

  .home-feature-item p {
    font-size: 0.8em;
    font-family: $text_font_family;
  }

  .home-feature-item h4 {
    font-weight: $pq_regular;
  }

  /* PRODUCT TEMPLATE */
  .product-header {
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0;
  }

  .product-wrapper {
    margin: 0 $pq_margin;
  }

  .product-wrapper h1 {
    text-align: center;
    color: $pq_key_color;
  }

  .product-wrapper p {
    font-family: $text_font_family;
    font-size: $pq_font_regular;
    text-align: center;
    color: $pq_dark_grey;
  }

  .pq-scroll-button {
    display: block;
    width: 60px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 30px);
  }

  .product-mobile-features {
    display: block;
  }

  .product-web-features {
    display: none;
  }

  .product-screens.mobile {
    display: block;
  }

  .product-screens.web {
    display: none;
  }

  /* CATEGORY TEMPLATE */

  .category-icons img {
    width: 100%;
  }

  .category-banner {
    height: 80vh;
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .category-banner-mobile {
    display: block;
  }

  .category-banner-web {
    display: none;
  }

  .category-icons {
    display: flex;
    height: 20vh;
  }

  .category-container {
    position: absolute;
    bottom: 50px;
    text-align: right;
    width: 70%;
    color: $pq_support_color;
    right: $pq_margin;
  }

  .category-intro {
    height: 100vh;
    background: $pq_key_color;
    position: relative;
    padding-right: $pq_margin;
  }

  .category-intro img {
    position: absolute;
    height: 30%;
    top: 35%;
  }

  .category-intro-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    align-items: center;
  }

  .category-intro-content-wrapper {
    width: 60%;
    color: $pq_support_color;
    text-align: right;
    font-size: $pq_font_small;
  }

  .category-intro-content-wrapper p {
    text-align: right;
  }

  .category-intro-content-wrapper a {
    padding: 5px 20px;
    background: $pq_support_color;
    color: $pq_key_color;
    text-decoration: none;
  }

  .category-product-details {
    display: flex;
  }

  .category-product-details img {
    width: 30%;
  }

  .category-product-text {
    width: 70%;
    margin-left: $pq_margin;
    text-align: center;
  }

  .category-product hr {
    width: 75%;
    margin-top: 25px;
  }

  .category-products {
    margin: 0 $pq_margin;
    padding: $pq_margin 0;
  }

  .category-product-text h5 {
    color: $pq_key_color;
    margin: 5px 0;
    font-size: $pq_font_mid_large;
    font-weight: $pq_regular;
  }

  .category-container h1 {
    font-weight: $pq_regular;
  }

  .category-product-text p {
    color: $pq_dark_grey;
    margin: 5px 0;
    font-family: $text_font_family;
    font-size: $pq_font_small;
    margin-bottom: 10px;
  }

  .category-product-text a {
    padding: 8px 10px;
    font-size: $pq_font_small;
    background: $pq_key_color;
    color: $pq_support_color;
    text-decoration: none;
    font-family: $text_font_family;
    margin-top: 5px;
  }

  /* CONTACT */
  .contact-top {
    flex-direction: column;
  }

  .contact-bottom {
    flex-direction: column-reverse;
  }

  .contact-half {
    width: 100%;
  }

  .contact-form {
    width: 100%;
    margin-bottom: $pq_margin;
  }

  .contact-regions {
    width: 100%;
  }

  .contact-partners {
    width: 100%;
  }

  /* ABOUT */
  .about-title {
    height: auto;
    margin: auto;
  }

  .about-leadership {
    flex-direction: column-reverse;
  }

  .about-leadership-img {
    width: calc(100% - 50px);
  }

  .about-leadership-details {
    width: calc(100% - 50px);
  }

  .about-leadership-details p {
    text-align: justify;
  }

  .leader-social {
    text-align: center;
  }

  .blue-banner {
    margin-top: calc(100vh / 10);
  }

  .story-section {
    margin: 5% 10%;
  }

  .about-leadership {
    margin: 0;
  }

  /* NEWS */

  .news-section {
    flex-direction: column;
    height: auto;
    min-height: fit-content;
  }

  .news-section-left {
    width: 100%;
  }

  .news-item {
    flex-direction: column;
    height: auto;
    margin-right: auto;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(161, 161, 161, 0.5);
  }

  .news-item-img {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .news-item-img img {
    height: auto;
    margin-top: 10%;
    width: 100%;
    transform: scale(1.2);
  }
  .news-item:hover > .news-item-img img {
    transform: scale(1.2);
  }

  .news-item-details {
    width: 90%;
    padding: 5%;
  }
  .news-item:hover > .news-item-details {
    transform: scale(1);
  }

  .news-item-details h5,
  span {
    text-align: center;
  }
  .news-item-details p {
    text-align: justify;
  }
  .news-item-details a {
    margin: auto;
  }
  .news-section-right {
    width: 100%;
    height: auto;
    margin-top: $pq_margin;
  }

  .news-comment-logo-wrapper {
    display: none;
  }

  .a-news-image-row-wrapper {
    height: auto;
  }

  .a-news-image-row-wrapper img {
    height: auto;
    width: 100%;
  }

  /* DEMO */

  .demo-container {
    justify-content: center;
    align-items: center;
  }

  .demo-button h1 {
    text-align: center;
  }

  .demo-img {
    width: 90%;
  }

  .demo-img img {
    height: auto;
    width: 100%;
  }

  .demo-button {
    margin-right: 0;
    width: 90%;
  }

  /* TEAM */
  .team-image-row-wrapper {
    height: auto;
  }

  .team-image-row-wrapper img {
    height: auto;
    width: 100%;
  }

  .team-image-description {
    height: auto;
    flex-direction: column-reverse;
  }

  .team-image-desc {
    width: 100%;
  }

  .team-image-img {
    width: 100%;
  }

  .team-image-img img {
    width: 70%;
    height: auto;
  }

  
  /*case_studies-mobile*/
  #case-studies-vector {
    text-align: center;
    margin: auto;
  }
  #case-studies-vector p {
    font-size: $pq_font_regular;
    font-weight: $pq_light;
    color: $pq_key_color;
    padding-top: $pq_margin;
  }

  #case-studies-vector img {
    width: 50%;
    padding-bottom: $pq_margin;
    margin: auto;
  }

  #case-studies-sec {
    background-color: $pq_key_color;
    height: auto;
    padding: 10px 30px;
  }

  #case-studies-item {
    display: flex;
    flex-direction: column-reverse;
    padding-top: $pq_margin;
  }

  #case-studies-title {
    height: auto;
    width: 100%;
    text-align: center;
  }

  #case-studies-title p {
    font-size: $pq_font_mid_large;
    font-weight: $pq_regular;
    color: $pq_key_color;
    margin-bottom: $pq_margin;
  }
  #case-studies-title a {
    padding: 10px 30px;
    font-size: 22px;
    font-weight: 500;
    color: $pq_key_color;
    background-color: $pq_support_color;
  }
  #case-studies-img {
    width: 100%;
    height: 100%;
    max-width: 100px;
    min-width: 0;
  }

  .case-study-description {
    width: 100%;
    text-align: center;
  }

  .case-study-content {
    flex-direction: column-reverse;
  }

  .case-study-image {
    width: 100%;
  }

  .case-study-image img {
    width: 100%;
  }

  .case-study-component {
    height: auto;
    margin: 0;
  }

  .case-study-btn-container div {
    margin-left: auto;
  }

  /*use-case-mobile*/
  .usecase-iconlist {
    flex-direction: column;
    height: auto;
    width: 80%;
    padding: 40px;
  }
  .usecase-icon {
    text-align: center;
    padding-bottom: 25px;
  }

  .usecase-vector img {
    width: 50%;
  }

  .usecase-icon:hover {
    background-color: #124370;
  }
  .use-case-item p {
    text-align: justify;
  }

  /* Datasheet */
  .datasheet-container {
    margin: 0 $pq_margin;
    width: 90%;
  }

  .datasheet-confirmed {
    margin: 0;
  }

  .datasheet-downloader {
    flex: 1 0 45%;
  }

  .datasheet-download-item {
    width: 40vw;
    height: 40vw;
  }

  .datasheet-download-item img {
    margin-top: 5px;
  }

  .datasheet-download-item p {
    margin: 0;
    font-size: $pq_font_regular;
  }

  .global-location-section{
width: 40%;
margin-left: 7%;
  }

  .global-location-adresses{
    width: 100%;
    height: auto;

  }

  
}



/*################# Wispamerica 2024 styles ################# */


.wispamericaTop {
  height: 80vh;
}

.wispamericaTop-container::before{
  content: '';
  position: absolute;
  background-color: rgba(13, 40, 65, 0.847);
  width: 100%;
  height: 80vh;
  opacity: 1;
  mix-blend-mode: hard-light;
}

.wispamericaTop-container{
  background: url('../public/assets/wispapalooza/oklahoma.jpg' );
  height: 80vh;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wispamericaTop-oklahoma{
  color: rgba(232, 230, 230, 0.147);
  font-family: Montserrat;
  font-size:120px;  
  font-style: normal;
  font-weight: 900;
  mix-blend-mode:screen;
  z-index: 5;
  margin-top: 5px;
}

.wispamericaTop-header-middle{

}

.wispamericaTo-meetus{
  position:absolute;
  margin-top: -225px;
  margin-left: 95px;
color: #FFF;
font-size: 55px;
font-style: normal;
font-weight: 900;
font-family: 'montserrat';
z-index: 10;


}

.wispamericaTop-person{
  color:#FFC526 ;

}
.wispamericaTop-container img{
  z-index: 100;
 margin-top: 100px;
}

.wispamericaTop-text{
  margin-top: -100px;
  color: white;
  z-index: 100;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  width: 650px;
}

.wispamericaTop-booth{
  padding: 1px 5px;
  background-color: #08043b;
  border-radius: 10px;
}

.wispamericaEventTab{
  position: absolute;
  top: 83%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.wispamericaEventTab-tab{
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 0px 30px;
  background-color: white;
  width: 80%;
  height: 90px;
  border-radius: 9px;
  box-shadow: 0px -5px 60px -3px rgba(0, 0, 0, 0.25);
}

.wispamericaEventTab-location{
  color: rgba(0, 0, 0, 0.53);
  font-weight: 600;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 3%;
  font-size: 18px;
  
}



.wispamericaEventTab-date1{
  color: rgba(0, 0, 0, 0.53);
  font-weight: 600;
  font-size: 17px;
  margin-left: 30px;
  margin-right: 60px;
}

.wispamericaEventTab-days{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  margin-left: 50px;
  margin-right: 25px;
}

.wispamericaEventTab-number{
  margin: 0;
  color: rgba(0, 0, 0, 0.78);
font-family: 'inter', 'montserrat';
font-size: 22px;
font-style: normal;
font-weight: 900;
line-height: normal;
}

.wispamericaEventTab-content{
  margin: 0;
  color: rgba(117, 116, 116, 0.92);
font-family: 'Barlow','montserrat';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.wispamericaEventTab-date{
  font-size: 15px;
  margin: 0;
}

.wispamericaEventTab-addtoCalendar{
  
  // margin-left:  120px;
  cursor: pointer;
  border: none;
  font-family: 'montserrat';
  background-color: #0D2841;
  color: white;
  font-weight: 600;
  font-size: 13px;
  padding: 10px 15px;
  border-radius: 50px;
  margin-left: 30px;
}



.Wispamerica-widistandout{
  margin-top:80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Wispamerica-widistandout-grid{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  flex-wrap: wrap;
}

.Wispamerica-widistandout-title{
  color: #0D2841;
font-size: 37px;
font-style: normal;
font-weight: 700;
}

.Wispamerica-widistandout-subtitle{
  margin-top:-20px;
  color: #0D2841;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 500;
}

.Wispamerica-widistandout-feature{

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-top: 40px;
}


.Wispamerica-widistandout-circle{
  width: 150px;
  height: 150px;
  border: 2px solid #0D2841;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Wispamerica-widistandout-topic{
color: #FFC526;
font-size: 22px;
font-style: normal;
font-weight: 600;
text-align: center;
margin-top: 40px;
width: 70%;
}

.Wispamerica-widistandout-discription{
  color: rgba(0, 0, 0, 0.62);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 80%;
  margin-top: -10px;
}

.Wispamerica-whatwedo{
  width: 100%;
  margin-top: 40px;
}
.Wispamerica-whatwedo-container{
  display: flex;
  margin: 20px 50px;
  border-radius: 35px;
  background: #0D2841;
  padding: 50px;
  padding-bottom: 30px;
  color: white;
}

.Wispamerica-whatwedo-container h2{
  color: white;
  font-size: 35px;
  margin-left: 5px;

}

.Wispamerica-whatwedo-container p{
  text-align: justify;
  font-size: 18px;
  margin-top: -20px;
  font-weight: 500;
  color: rgba(245, 245, 245, 0.874);
}

.Wispamerica-whatwedo-container-left{
  width: 50%;
}

.Wispamerica-whatwedo-container-right{
  width: 50%
}

.Wispamerica-whatwedo-container-right img{
  margin-left: 60px;
  margin-top: 50px;
  width:75%;
}


.Wispamerica-whatwedo-watermark{
  position: absolute;
  right:-130px;
  transform: rotate(-90deg);
  font-family: 'inter';
  font-size: 90px;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.099);
  margin-top: 200px;

}


.PreEvent-middle{

}

.PreEvent-middle-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px;
  padding: 20px 80px;
  background-color: #0D2841;
  color: white;
  border-radius: 15px;
}


.PreEvent-middle{
 text-align: justify;
}

.PreEvent-middle h3{
  text-align: center;
  font-size: 24px;
}



// ########  Privacy Policy page  ########


.privacyPolicy-container{
}

.privacyPolicy-top{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $pq_key_color;
  height: 70vh;
  font-family: 'Montserrat';
  color: white;
}

.privacyPolicy-updateDate{
  display: flex;
  gap: 8px;
  margin-top: -30px;
  font-size: 1rem;
  color: rgb(245, 245, 245);
}

.privacyPolicy-top h1{
font-size: 38px;
}

.privacyPolicy-top p{
font-weight: 500;
}


.privacyPolicy-mid{
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.privacyPolicy-mid-container{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 88%;
  border-radius: 15px;
  padding: 50px 90px;
  box-shadow: 0px -5px 60px -3px rgba(0, 0, 0, 0.25);
  text-align: justify;
}

.privacyPolicy-mid-container h2{
font-size: 23px;
color: $pq_key_color;
}

.privacyPolicy-content{
  font-size: 18px;
  margin-top: -8px;
}

// .privacyPolicy-height{
//   height: 240vh;
// }

// .termsOfServices-height {
//   height: calc(100vw - 50vh + 200px); /* Adjust the formula to fit your needs */
// }

.PrivacyPolicy-main{
  position: relative;
}

.privacyPolicy-mid-container-qoute{
  font-size: $pq_font_regular;
}





/* WISPAPALOOZA */
.wispapalooza {

  min-height: 30vh;
  background: $pq_key_color;
  display: flex;
  justify-content: center;
  color: #ffffff;
  border: 5px solid $pq_key_color;
  text-align: start;
  z-index: 00;
  width: 100%;
  
}

.wispapalooza-container {
  width: 100%;
  margin: 0 5%;
  text-align: left;
  margin-top: 2%;
}

.wispapaloozaTo-heading {
  font-size: 70px;
}

.wispapaloozaTo-body {
  font-size: 25px;
}

.wispapalooza-specialities {
  height: 25vh;
  background: $pq_darker_key;
  display: flex;
  justify-content: center;
  color: #ffffff;
  border: 5px solid $pq_darker_key;
  text-align: center;
  width: 100%;
}

.wispapalooza-specialities-container {
  width: 100%;
  margin: 0 5%;
  text-align: center;
  margin-top: 2%;
}

.wispapaloozaTo-specialities-heading {
  font-size: 60px;
  margin-top: 10px;
}

.wispapaloozaTo-specialities-heading2 {
  font-size: 60px;
  margin-top: 10px;
}

.wispapaloozaTo-specialities-column {
  display: grid;
  grid-template-columns: 50% 0fr 0fr;
  margin-top: 50px;
  font-size: 40px;
  width: 100%;
}

.wispapaloozaTo-whoweare-column {
  display: grid;
  grid-template-columns: 60% 0fr 0fr;
  margin-top: 0px;
}
.video{
  margin-top: 8vh;
  min-height: 21em;
  height: 50%;
  width: 1210%;
  margin-left: 5vh;
  margin-bottom: 40px;

}

.wispapaloozaTo-wondering-column {
  display: grid;
  grid-template-columns: 50% 10fr 20fr;
  margin-top: 0px;
}
.mapimage {
  margin-top: 8vh;
  min-height: 46vh;
  min-width: 42vh;
  width: auto;
  height: auto;
  margin-bottom: 6vh;
  background-image: url('../public/assets/wispapalooza/wishpa_2023_map.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 27vh;
  transition: transform .3s;
  box-shadow: 1px 4px 8px rgba(3, 0, 0, 0.8); /* You can adjust the shadow values as needed */
}

.mapimage:hover{
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.2); 
}
.mapimage2{
  margin-top: 8vh;
  min-height: 42vh;
  min-width: 42vh;
  width: auto;
  height: auto;
  box-shadow: 0px 0px 42px 21px rgba(153, 152, 148, 0.35);
  // width: 25rem;
  margin-bottom: 6vh;
  background-image: url('../public/assets/wispapalooza/image_2023-02-21_14_09_45.png');
  background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  margin-left: 27vh;
  transition: transform .3s;

}
.mapimage2:hover{
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Safari 3-8 */
  transform: scale(1.3); 
}
.wispapaloozaTo-whychoos-column {
  display: grid;
  grid-template-columns: 40% 0fr 0fr;
  margin-top: 0px;
  width: 100%;
}

.wispapalooza-standout {
  min-height: 50vh;
  background: #ffffff;
  display: flex;
  justify-content: center;
  text-align: center;
}

.wispapalooza-wondering  {
  min-height: 40vh;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}


.wisp-wondering{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: 50px ;
  padding-bottom: 50px;
}

.wisp-wondering-heading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 6;
}

.wisp-wondering-heading-p{
  font-size: 40px;
  font-weight: 600;
  margin-top: 20px;
}

.wisp-wondering-subheading{
  font-size: 23px;
  margin-top: -10px;
}

.wisp-wondering-timedata{
display: flex;
flex-direction:row;
justify-content: space-around;
align-items: center;
padding: 20px 100px;
flex-wrap: wrap;
}


.wisp-wondering-floormap{
flex: 4;
transition: transform 0.3s ease, box-shadow 0.3s ease;


}

.wisp-wondering-floormap:hover{
  transform: scale(1.1); 
}


.wispapalooza-whoweare {
  min-height: 40vh;
  background:$pq_key_color;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  
}
.wispapalooza-banner{
  min-height: 50vh;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.wispapalooza-banner-container {
  width: 100%;
  margin: 0 5%;
  justify-content: center;
  text-align: center;
  align-items: center;

}
.banner{
  margin-top: -50px;
  margin-left: 15vh;
  width: 550px;
  height: 550px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 42px 21px rgba(153, 152, 148, 0.35);
border-radius: 10px;
margin-bottom: 40px;
background-repeat: no-repeat;
background-size: cover;
background-position: top;
background-image: url("../public/assets/wispapalooza/Web_Post.jpg");
}

.banner2{
  margin-top: 8vh;
  width: 60vh;
  min-height: min-content;
  height: min-content;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 42px 21px rgba(153, 152, 148, 0.35);
border-radius: 39px;
margin-bottom: 40px;
margin-left: 20px;
}

.wispapaloozaTo-banner-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0px;
  width: 100%;
}

.bannerhead{
  margin: 10px;
  font-size: 50px;
  font-weight: bolder;

}
.bannerbody{
  font-size: 25px;
  margin: 20px;
  margin-top: 30px;
}
.bannerbody2{
  font-size: 20px;
  margin: 10px;
}
.wispapalooza-whoweare-container {
  width: 100%;
  margin: 0 5%;
  text-align: left;
}

.wispapalooza-whoweare-blue {
  color: $pq_light_blue;
}

.wispapalooza-whoweare-hr {
  width: 100px;
  height: 10px;
  background-color: $pq_light_blue;
  margin-bottom: 10px;
}

.wispapalooza-whoweare-bold {
  font-weight: bold;
  margin-bottom: 20px;
}

.wispapalooza-whoweare-body {
  font-size: 28px;
  line-height: 40px;
  margin-bottom:30px;
  letter-spacing: 1px;
  margin-top: 30px;
}

.wispapalooza-wondering-body {
  font-size: 28px;
  line-height: 40px;
  margin-bottom:120px;
  letter-spacing: 1px;
  margin-top: 30px;
}

.wispapalooza-header-button {
  background-color: $pq_key_color; /* Green */
  border: none;
  color: #fbc21d;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 22px;
  margin-right: -60px;
  width: 150px;
  cursor: pointer;
  border: 2px solid #fbc21d;
  border-radius: 46px;
}

.wispapalooza-header-button:hover {
  font-weight: $pq_bold;
}

.wispapalooza-button {
  background-color: $pq_key_color; /* Green */
  border: none;
  color: #ffffff;
  padding: 20px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  margin-right: -60px;
  width: 260px;
  cursor: pointer;
  border: 2px solid $pq_key_color;
  border-radius: 10px;
  // margin-top: -100;
  transition: transform 0.5s;
}

.wispapalooza-button:hover {
  font-weight: $pq_bold;
  // transform: scale(1.);
  transform: scaleY(1.1)
  // margin-left: 20px;
}

.wispapalooza-whatwehave {
  min-height: 50vh;
  height: auto;
  display: flex;
  justify-content: center;
  color:#ffffff;
  text-align: start;
  width: 100%;
  // background-color:$pq_key_color;
  color: $pq_darker_key;
  
}

.wispapaloozaTo-whatwehave-heading {
  font-size: 50px;
  font-weight: $pq_bold;
  margin-top: -50px;
  margin-bottom: 50px;
  text-align: center;
}

.wispapaloozaTo-whatwehave-heading2 {
  font-size: 20px;
  margin-top: -35px;
  margin-bottom: 50px;
  text-align: center;
}
.wispapalooza-meetus-body{
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
  color:#8B8080;
  letter-spacing: 0.8px;
  margin-left: 0.8rem;
}

.wrapper-time {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
  width: 200%;
}

.wispamerica-floormap {
  margin-left: 190px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.wispamerica-floormap:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Safari 3-8 */
  transform: scale(1.3);
  cursor: pointer;
}

.widi-time-box{
  background-color: #ffffff;
  min-width: 33vh;
  width: auto;
  border: 1px solid #ebebeb;
  box-shadow: rgba(108, 108, 114, 0.37) 0px 0px 27px 10px;
  border-radius: 10px;
  transition: transform .3s;
  margin-bottom: 10px;
}

.wrapper-widi {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // grid-auto-rows: auto;
  row-gap: 1em;
  column-gap: 1em;
  margin-left: 10vh;

}

.widi-box{
  background-color: #ffffff;
  min-width: 24vh;
  width: auto;
  border: 1px solid #ebebeb;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  transition: transform .3s;
}

.widi-box:hover {
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Safari 3-8 */
  transform: scale(1.3); 
}

.what-we-have-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 17em;
  column-gap: 1em;
  margin-top: 2rem;
  margin-bottom: 2vh;
}
.what-we-have-box{
  background-color: #ffffff;
  // min-width: 38vh;
  width: auto;
  transition: transform .2s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #091927;
  cursor: pointer;
  background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
   
}
.what-we-have-box2{
  background-color: #ffffff;
  // min-width: 38vh;
  width: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  transition: transform .2s;
  color: #091927;
   
}
.whatwehave-box1{
  background-image: url("../public/assets/wispapalooza/widi.jpeg");
  min-height: 10em;
  height: auto;
}
.whatwehave-box2{
  background-image: url("../public/assets/wispapalooza/sms.png");
  min-height: 10em;
  height: auto;
}
.whatwehave-box3{
  background-image: url("../public/assets/wispapalooza/tcp.png");
  min-height: 10em;
  height: auto;
}
.whatwehave-box4{
  background-image: url("../public/assets/wispapalooza/CeyAnalyst_1.png");
  min-height: 10em;
  height: auto;
}
.name{
  text-align: center;
  margin-top: 15px;
  font-size: 30px;
  text-decoration: none;
  font-weight: bold;
}
.nameimage{
  width: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;

}
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.what-we-have-box2:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Safari 3-8 */
  transform: scale(1.3); 
}

.img1-wispapalooza{
width: 100%;
margin-top: -5px;
  z-index: -1;
}

.img2-wispapalooza{
  width: 100%;
  margin-top: 50px;
    z-index: -1;
    margin-bottom: -15px;
  }

.img3-wispapalooza{
  width: 100%;
    margin-top: -400px;
    z-index: -1;
  }
  .wispapaloozaTo-top-column{
    display: grid;
    grid-template-columns: 60%  100fr 10fr;
    margin-top: 30px;

    right: 0;
  }
  .wispapalooza-logo{
    width: 100%;
    margin-top: -20px;
  }
  .boxdata{
    margin: 10px;
    align-items: center;
    text-align: center;
    align-content: center;
  }

  .boxdata-title{
    text-align: center;
    font-size: 22px;
    color: #F79E0E;
  }

  .boxdata-data{
    text-align: center;
    font-size: 16px;
    color:  #5B5959;
    margin-top: 10px;
    
  }

  .box-image{
    width: 85px;
    height: 85px;

  }

  .wispapalooza-meetus {
    min-height: 55vh;
    height: auto;
    display: flex;
    justify-content: center;
    color:#ffffff;
    text-align: start;
    width: 100%;
  }

  .timedata{
    margin: 10px;
    margin-top: 50px;
    align-items: center;
    text-align: center;
    align-content: center;
    padding: 30px;
    border-radius: 15px; 
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  }

  .timedata-title{
    text-align: center;
    font-size: 22px;
    color: #000000;
    font-weight: bolder;
    // margin-top: -12px;
  }

  .timedata-data{
    text-align: center;
    font-size: 17px;
    color:  #5B5959;
    margin-top: 8px;
    
    
  }

  .time-image{
    width: 40px;
    height: 40px;
  }

  .wispapalooza-meetus {
    min-height: 55vh;
    height: auto;
    display: flex;
    justify-content: center;
    color:#ffffff;
    text-align: start;
    width: 100%;
  }

  .wispapaloozaTo-meetus-heading {
    font-size: 60px;
    font-weight: $pq_bold;
    margin-top: 50px;
    margin-bottom:-70px;
    text-align: left;
    color: black;
    margin-left: 0.5rem;

  }

  .meetus-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 30vh;
    column-gap: 2.5em;
    margin-left: 7vh;
    margin-top: 2rem;
  }
  

  .meetus-box{
    background-color: #ffffff;
    width: auto;
    border: 1px solid #ebebeb;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: transform .2s;
    color: #091927;
  }
  .meetus-box1{
    background-image: url("../public/assets/wispapalooza/6.jpg");
  }
  .meetus-box2{
    background-image: url("../public/assets/wispapalooza/7.jpg");
  }
  .meetus-box3{
    background-image: url("../public/assets/wispapalooza/8.jpg");
  }


//Wishpa Mobile responsive 
@media only screen and (max-width: 1020px) {

  .wisp-wondering{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

  }

  .usecase-icon-button-container {
    flex-wrap: wrap;
    justify-content: space-around; 
  }


  .wispapalooza-logo{
    width: 40%;
    margin-top: 10px;
  }
  
  .wispapaloozaTo-top-column{
    display: grid;
    grid-template-columns: 100%   ;
    margin-top: 80px;
    text-align: center;
  }
  .wispapaloozaTo-whychoos-column {
    display: grid;
    grid-template-columns: 100% ;
    margin-top: 0px;
    width: 100%;
  }
 .wispapaloozaTo-specialities-heading {
    font-size: 18px;
    margin-top: 40px; 
    text-align: center;
  }
  .wispapalooza-button {
    background-color: $pq_key_color; /* Green */
    border: none;
    color: #ffffff;
    padding: 20px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    margin-right: -60px;
    width: 260px;
    cursor: pointer;
    border: 2px solid $pq_key_color;
    border-radius: 10px;
    // margin-top: -100;
    transition: transform 0.5s;
    margin-top: -20px;
    margin-bottom: 30px;
    margin-left: -50px;
  }
  .wrapper-widi {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important; //adding two columns
    row-gap: 1em;
    column-gap: 1em;
  }
  .widi-box {
    background-color: #ffffff;
    min-width: 24vh;
    border: 1px solid #ebebeb;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    transition: transform .3s;
  }

  .widi-box:hover {
    color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    -ms-transform: scale(1.3); /* IE 9 */
    -webkit-transform: scale(1.3); /* Safari 3-8 */
    transform: scale(1.1); 
    
  }
  .what-we-have-box2:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
  }

  .wispapaloozaTo-wondering-column {
    display: grid;
    grid-template-columns: 50% 10fr 20fr;
    margin-top: 0px;
    text-align: center;
  }

  .mapimage{
    margin-top: 8vh;
    min-height: 46vh;
    min-width: 42vh;
    width: auto;
    height: auto;
    // width: 25rem;
    margin-bottom: 3vh;

 
    background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    margin-left: 27vh;
    transition: transform .3s;
    
    
  }
  .mapimage:hover{
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.1); 
  }

  .meetus-wrapper {
    display: grid ;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 30vh;
    column-gap: 2.5em;
    margin-top: 2rem;
  }

  .wispapaloozaTo-banner-column {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top:10px !important;
    width: 100%;
  }

  .banner{
    margin-top: 20px;
    margin-left: 17% !important;
    width: 300px !important;
    height: 300px !important;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 42px 21px rgba(153, 152, 148, 0.35);
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-image: url("../public/assets/wispapalooza/Web_Post.jpg");
  }

  .banner2{
    width: auto !important;
    height: 200px;
  margin-left: -2%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 42px 21px rgba(153, 152, 148, 0.35);
  border-radius: 39px;
  margin-bottom: 40px;
  margin-top: 0px;
  }

  .wispapaloozaTo-wondering-column {
    display: flex !important;
    justify-content: center;
    align-items: center ;
    flex-direction: column ;
    grid-template-columns: 50% 10fr 20fr;
    margin-top: 0px;

  }

  .meetus-box{
    background-color: #ffffff;
    width: 100% !important;
    border: 1px solid #ebebeb;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: transform .2s;
    color: #091927;

  }



  
  .banner-indicator {
    display: flex;
    position: absolute;
    bottom: 10px;
    height: 10%;
    width: 100%;
    justify-content: center;

  }

  .indicator-item {
    width: 10px;
    margin: 10px;
    transition: 0.5s;
    padding-top: 4px;

  }

  .indicator-item.selected {
    width: 20px;
    transition: 0.5s;
    padding-top:5px;
  }

  .indicator-item img {
    object-fit: contain;
    height: 100%;
  
  }

  .wispamericaTop-oklahoma{
    font-size:100px !important;  
  }

  .wispamericaTo-meetus{
    margin-top: -185px !important;
    margin-left: 70px !important;
    font-size:47px;
  }

  .Wispamerica-widistandout-topic{
    font-size: 17px !important;
  }

  .Wispamerica-widistandout-discription{
    font-size: 14px !important;
  }

  .Wispamerica-whatwedo-container-right img {
    width:90%;
  }



.wispamericaTop-container{
  height: 60vh;
}

.wispamericaTop-container::before{
    height: 60vh;
}

.wispamericaTop-container img {
  margin-top: 30%;
}

.wispamericaEventTab{
  top: 56% !important;
}

.Wispamerica-widistandout{
  
}

 
}

@media only screen and (max-width: 450px) {

  .events-carousel-card {
  flex: 0 0 25%;
}

.events-header h4 {
  font-size: 26px;
 
}


.events-header p{
  margin-top: 5px;
 font-size: 18px;
}

  .events-card{
    border-radius: 15px;
    background-color: #0f273e;
    width: 270px;
  }

  .wispamericaTop-header-middle p{
    opacity: 0;
    overflow: hidden;
    display: none;
  }

  .wispamericaTo-meetus{
    font-size: 25px !important;
    display: none;
  }

  .wispamericaTo-meetus-mobile{
    display: block !important;
    color: #FFF;
font-size: 25px;
font-style: normal;
font-weight: 900;
font-family: 'montserrat';
z-index: 10;
margin-top: 100px;
  }

  .wispamericaTop-text{
    margin-top: -10px !important;
    color: white;
    z-index: 100;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    width:90% !important;
  }


  .PreEvent-middle-container{
    margin: 5px;
    padding: 10px 10px;
    font-size: 15px;
  }

  .privacyPolicy-top h1{
    font-size: 28px;
  }
  .privacyPolicy-mid-container-qoute{
    font-size: 16px;
  }
  

  .privacyPolicy-content{
    font-size: 16px;
  }

  .privacyPolicy-mid-container h2{
    font-size: 19px;
    }

    .privacyPolicy-mid-container{
      padding: 50px 5px;
      width: 97%
    }
   
  .wrapper-widi {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important; //adding two columns
    row-gap: 1em;
    column-gap: 1em;
  }

  
  .wispamericaTo-meetus{
    margin-top: -105px !important;

  }

  .wispamericaTop-containe{
    padding-top: 100px !important;
  }

  


}

@media only screen and (max-width: 1300px) {
  .wispapaloozaTo-whoweare-column {
    display: grid;
    grid-template-columns: 50% 0fr 0fr;
    margin-top: 0px;
  }
  .wrapper-widi {
    display: grid;
    grid-template-columns: repeat(2, 1fr) ; //adding two columns
    row-gap: 1em;
    column-gap: 1em;
  }


  
.footerNew-section1{
  width: 30%;
  margin-right: 40px;
  margin-top: 20px;
  
  
  }
  
  
  .footerNew-section2{
    width: 25%;
  }
  .footerNew-section3{
    width: 25%;
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
  
  }
  
  .footerNew-section4{
    width: 25%;
    display: flex;
    justify-content: flex-end;
    margin-left: -25px;
  }
  
}


@media only screen and (max-width: 1020px) {

  .wispapalooza-button {
    background-color: $pq_key_color; /* Green */
    border: none;
    color: #ffffff;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin-right: -60px;
    width: 130px;
    cursor: pointer;
    border: 2px solid $pq_key_color;
    border-radius: 5px;
    margin-top: -100;
  }

  .wispapaloozaTo-banner-column {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 0px;
  width: 100%;
  }

  .banner2{
    width: 340px;
    height: 200px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 42px 21px rgba(153, 152, 148, 0.35);
  border-radius: 39px;
  margin-bottom: 40px;
  margin-top: 0px;
  }

  .banner{
    margin-top: 20px;
    margin-left: 8vh;
    width: 225px;
    height: 225px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 42px 21px rgba(153, 152, 148, 0.35);
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-image: url("../public/assets/wispapalooza/Web_Post.jpg");
  }

  .timedata-title{
    text-align: center;
    font-size: 16px;
    color: #000000;
    font-weight: bolder;
    // margin-top: -12px;
  }

  .timedata-data{
    text-align: center;
    font-size: 13px;
    color:  #5B5959;
    margin-top: 8px;
    
  }

  .time-image{
    width: 20px;
    height: 20px;
  }


  
  .meetus-box{
    background-color: #ffffff;
    width: 40vh;
    border: 1px solid #ebebeb;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: transform .2s;
    color: #091927;
  }
  .widi-box{
    background-color: #ffffff;
    min-width: 25vh;
    width: auto;
    border: 1px solid #ebebeb;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    transition: transform .3s;
  }

  .wispapaloozaTo-whatwehave-heading {
    font-size: 20px;
    font-weight: $pq_bold;
    margin-top: -50px;
    margin-bottom: 50px;
    text-align: center;
  }

  .wispapaloozaTo-whatwehave-heading2 {
    font-size: 15px;
    margin-top: -35px;
    margin-bottom: 50px;
    text-align: center;
  }
  .wispapalooza-meetus-body{
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 10px;
    color:#8B8080;
    letter-spacing: 0.8px;
    margin-left: 0.8rem;
  }
  

  .name{
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
    text-decoration: none;
    font-weight: bold;
  }
  .nameimage{
    width: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  
  }
  .box-image{
    width: 50px;
    height: 50px;

  }
  .boxdata-title{
    text-align: center;
    font-size: 15px;
    color: #F79E0E;
  }

  .boxdata-data{
    text-align: center;
    font-size: 12px;
    color:  #5B5959;
    margin-top: 10px;
    
  }
  .wispapaloozaTo-meetus-heading {
    font-size: 20px;
    font-weight: $pq_bold;
    margin-top: 15px;
    margin-bottom: -50px;
    text-align: left;
    color: black;
    margin-left: 0.5rem;
  }
  .wispapalooza-whoweare-hr {
    width: 50px;
    height: 5px;
    background-color: $pq_light_blue;
    margin-bottom: 10px;
  }
  .wispapaloozaTo-heading {
    font-size: 20px;
  }
  
  .wispapaloozaTo-body {
    font-size: 15px;
  }
  .wispapaloozaTo-specialities-heading2{
    font-size: 18px;
    margin-top: -50px;
    margin-left: -80px;
    margin-bottom: -100px;
  }
  .wispapaloozaTo-specialities-heading {
    font-size: 18px;
    margin-top: 40px;
  }
  
  .wispapaloozaTo-specialities-column {
    display: grid;
    grid-template-columns: 50% 20fr 0fr;
    margin-top: 50px;
    font-size: 15px;
  }
  .bannerhead{
    margin: 10px;
    font-size: 15px;
    font-weight: bolder;
  
  }
  .bannerbody{
    margin-top:15px ;
    font-size: 15px;
    // margin: 10px;
  }
  .bannerbody2{
    font-size: 13px;
    margin: 10px;
  }
  .meetus-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 30vh;
    column-gap: 2.5em;
    margin-left: 2vh;
    margin-top: 2rem;
  }
  .what-we-have-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 19em;
    column-gap: 3em;
    margin-top: 2rem;
    margin-bottom: 2vh;
  }
  .wrapper-widi {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    row-gap: 1em;
    column-gap: 1em;
    margin-left: 1vh;
  }
  .wispapaloozaTo-wondering-column {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0px;
  }
  .wispapaloozaTo-whoweare-column{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  margin-top: 0px;
  }
  .video{
    height: 40vh;
    min-width: 10vh;
    width: auto;
    margin: 10px;
    margin-bottom: 7vh;
  }
  .mapimage{
    margin-top: 10vh;
    height: 10rem;
    width: 18rem;
    background-image: url('../public/assets/wispapalooza/wishpa_2023_map.jpg');
    background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    margin-left: 1vh;
    transition: transform .3s;
  }
  .mapimage2{
    margin-top: 10vh;
    height: 10rem;
    width: 18rem;
    background-image: url('../public/assets/wispapalooza/image_2023-02-21_14_09_45.png');
    background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    margin-left: 1vh;
    transition: transform .3s;
  }
  .what-we-have-box2{
    background-color: #ffffff;
    min-width: 15vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    transition: transform .2s;
    color: #091927;
    margin-bottom: 30px;
  }
  .wispapalooza-whoweare-body {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 50px;
    letter-spacing: 1px;
    margin-top: 25px;
  }

  .wrapper-time {
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
    width:50% !important;
    margin-left: 350px;
  }

  .wispamerica-floormap{
    margin-top: 130px !important;
    margin-right: 120px ;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}
* {
  box-sizing: border-box;
}

.column1 {
  float: left;
  width: 50%;
  height: 10vh;
  padding: 10px;
  margin-top: -10vh;
  border-radius: 100% 100% 0% 0%;
  padding-right: 1100px;
  border: 5px solid rgb(255, 255, 255);
  background: rgb(
    255,
    255,
    255
  );
}
.column2 {
  float: right;
  width: 50%;
  padding: 10px;
  height: 10vh;
  margin-top: -10vh;
  border: 5px solid rgb(255, 255, 255);
  padding-left: 10px;
  border-radius: 100% 100% 0% 0%;
  background: rgb(255, 255, 255);
  padding-left: 1100px;
  z-index: 10; 
}

.row:after {
  content: "";
  display: table;
  clear: both;
  margin-top: -11vh;
}




//WispaImage Slider
.imageSlider {
  margin-top: 50px;
  margin-bottom: 50px;
}

.slider {
  height: 400px;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slider-track {
  display: flex;
  animation: scroll linear infinite;
  animation-duration: calc(20s * 2); /* Longer duration for smoother transitions */
}

.slider-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Moves only half the full content width to restart seamlessly */
  }
}

.slide {
  height: 200px;
  width: 450px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

.wispa-slider {
  width: 100%;
  border-radius: 3%;
  transition: transform 0.5s;
}

.wispa-slider:hover {
  transform: translateZ(10px);
}

.slider::before, .slider::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  content: '';
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.slider::before {
  left: 0;
  top: 0;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}












@media only screen and (max-width: 650px) {







  .imageSlider {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .slide {
    height: 100px;
    width: 225px;
  }
  .current-event{
    display: flex;
    align-items: center;
  justify-content: center;

  }

  
  .PreEvent-middle-container{
    margin: 20px;
    padding: 20px 20px;
    font-size: 15px;
  }

  .privacyPolicy-mid-container{
    padding: 50px 30px;
    width: 92%
  }

  .wispamerica-floormap{
 
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 200px !important;
  }
  
  .wrapper-time {
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
    width: 50%;
    margin-left: 0px !important;
    padding-left:150px !important;
  }

  .product-header-wrapper-p{
    width: 90%;
    text-align: justify;
    color: $pq_dark_grey;
    font-weight: 500;
  }

.wispa-logo{
  display: none;
}
.wispa-notification-text{
 text-align: center !important;
 font-size: 13px !important;

}

.wispa-notification-button{
  font-size: 13px !important;
}

.product-wrapper{
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}




.product-header-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-position:cover;
}

.product-wrapper{
  width: 100%;

}

.product-mockup{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 20px;
}

.product-title{
  font-size: 35px !important;
  width: 100%;
  margin-right: 130px;

}

.product-buttonNewWrapper{
  width: 100%;
  margin-top: 10px;
  text-align: center;
  margin-right: 140px;
}

.product-header-wrapper p {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-40%,-160%) rotate(-90deg); /* Adjusted for vertical centering */
  color: rgba(255, 255, 255, 0.844);
  font-family: 'Montserrat';
  font-size: 50px !important;
  font-weight: 600;
  opacity: 0.07;
  white-space: nowrap; /* Prevent text from wrapping */
}

.wispamericaTop-oklahoma{
  font-size:80px !important;  
}

.wispamericaTo-meetus{
  font-size: 35px;
  margin-top: -145px !important;
}

.wispamericaTop-text{
  font-size: 15px;
  width: 70%;
  margin-top: 30px;
}

.Wispamerica-widistandout-feature{
  width: 50% !important;
}

.Wispamerica-whatwedo-container{
flex-direction: column !important;
margin: 20px 20px;
}

.Wispamerica-whatwedo-container-left{
  width: 100% !important;
}
.Wispamerica-whatwedo-container-right{
  width: 100% !important
}

.Wispamerica-whatwedo-watermark{
  display: none;
}

.Wispamerica-video-iframe{
  width: 100% !important;
}

.wispamericaEventTab-tab{
  width: 90%;
}

.wispamericaEventTab-days{
  margin-left: 10% !important;
}

.global-location-section{
  width: 50%;
  margin: 0;
}
.global-locations-map-sl{
  width: 100%;
  height:100% ;
}

.global-locations-indicator-srilanka{
  margin-left:27%;
  margin-top: 18%;
  width: 13%;
}

.global-locations-indicator-australia{
  margin-left: 50%;
  margin-top: 32%;
  width: 13%;
}

.global-locations-indicator-america{
  margin-right: 41%;
  margin-bottom: -1%;
  width: 18%;
}

.global-location-region{
  font-size: 20px;
}

.global-location-address{
  font-size: 15px;
}

.global-location-email{
font-size: 15px;
}

.global-location-phoneNumber a{
  font-size: 15px;
}

.product-terms-continer{
  margin: 80px 10px;
}

.product-terms-left{
  display: none;
}

.product-terms-right{
  margin-left: 20px;
}

.product-terms-vl{
  margin-left: -15px;
}

.product-terms-continer {
  padding-right: 0px;
}

.webinar-header-title{
  font-size: 22px;
}

.webinar-header-subtitle{
  text-align: center;
  font-size: 12px;
  padding: 0px 20px;
}

.webinar-banner-content{
  justify-content: center;
  align-items: center;
  padding-left: 0px;
  text-align: center;
}

.webinar-banner-content-title{
  font-size: 20px;
}

.webinar-banner-content-live{
  display: none;
}

.webinar-banner-content-title br {
  display: none;
}
.webinar-banner-content-subtitle{
  font-size: 16px;
  text-align: center;
  padding: 5px 50px;
}


.webinar-banner-content-datetime{text-align: center;
font-size: 12px !important;}

.webinar-banner-content-hr{
  width: 70%;
}


.webinar-banner-content{
  padding-top: 00px !important;
}
.webinar-flyer
{
  flex-direction: column;
}

.webinar-flyer-content-title{

  font-size: 22px;
  text-align: center;
  margin-top: 30px;
}

.webinar-flyer-content-subtitle{
  padding: 10px;
  text-align: center;
}

.webinar-flyer-content-resource-1-title p{
  font-size: 15px;
}

}


// ############## Footer mobile responsive ###############
  
@media (min-width: 1100px) {
  .footer {
      display: none; /* Hide for screen sizes greater than or equal to 1200px */
  }
}

@media (max-width: 1100px) {
  .footerNew {
      display: none; /* Hide for screen sizes less than 1200px */
  }
}


@media only screen and (max-width: 1200px) {
.wispamericaEventTab-location{
font-size:14px ;
margin:10px !important
}

.wispamericaEventTab-date1{
  font-size:14px ;
  margin-left:10px !important;
  width: 30%;
  margin-right: 0px;
}

.wispamericaEventTab-vl{
  display: none;
}

.wispamericaEventTab-number{
  font-size:20px;
}
.wispamericaEventTab-content{
  font-size: 15px;
}

.wispamericaEventTab-addtoCalendar{
  font-size: 11px;
}


}
